import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import Navigation from '../../components/admin/Navigation';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/admin/icons/calendar.png';
import handleSortIcon from '../../assets/admin/icons/handleSortIcon.png';
import leftCursor from '../../assets/admin/icons/leftCursor.png';
import rightCursor from '../../assets/admin/icons/rightCursor.png';
import { getCashPagination } from '../../api/admin/cash';
import { ChevronDownIcon } from '@heroicons/react/solid';

const CashList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [goodsName, setGoodsName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [isItemLimitDropdownOpen, setIsItemLimitDropdownOpen] = useState(false);
  const [uniqueGoodsNames, setUniqueGoodsNames] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchCashData();
    fetchUniqueGoodsNames();
  }, [currentPage, itemsPerPage, goodsName, search, startDate, endDate, sortConfig]);

  const fetchCashData = async () => {
    try {
      const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        const tzOffset = d.getTimezoneOffset() * 60000;
        const localDate = new Date(d.getTime() - tzOffset);
        return localDate.toISOString().split('T')[0];
      };

      const adjustedEndDate = endDate ? new Date(endDate) : null;
      if (adjustedEndDate) {
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
      }

      const response = await getCashPagination(
        currentPage + 1,
        itemsPerPage,
        search,
        goodsName,
        formatDate(startDate),
        formatDate(adjustedEndDate)
      );

      let currentPageData = [...response.payments];
      if (sortConfig.key) {
        currentPageData = currentPageData.sort((a, b) => {
          const aValue = sortConfig.key.includes('.') 
            ? sortConfig.key.split('.').reduce((obj, key) => obj?.[key], a)
            : a[sortConfig.key];
          const bValue = sortConfig.key.includes('.')
            ? sortConfig.key.split('.').reduce((obj, key) => obj?.[key], b)
            : b[sortConfig.key];

          if (sortConfig.direction === 'ascending') {
            return aValue > bValue ? 1 : -1;
          } else {
            return aValue < bValue ? 1 : -1;
          }
        });
      }

      setData(currentPageData);
      setTotalItems(response.total);
      setTotalPages(Math.ceil(response.total / itemsPerPage));
    } catch (error) {
      console.error('결제 데이터 가져오기 실패:', error);
    }
  };

  const fetchUniqueGoodsNames = async () => {
    try {
      const response = await getCashPagination(0, Number.MAX_SAFE_INTEGER);
      const allGoodsNames = response.payments.map(item => item.goodsName);
      const uniqueNames = [...new Set(allGoodsNames)];
      setUniqueGoodsNames(uniqueNames);
    } catch (error) {
      console.error('고유한 상품 구분 목록 가져오기 실패:', error);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(0); // 1 대신 0으로 변경
  };

  const handleCategoryChange = (event) => {
    setGoodsName(event.target.value);
    setCurrentPage(0); // 1 대신 0으로 변경
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setCurrentPage(0); // 1 대신 0으로 변경
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map(item => item.id));
    }
  };

  const handleRowClick = (id) => {
    navigate(`/cash-editor/${id}`);
  };

  const fetchAllData = async () => {
    try {
      let allData = [];
      let page = 1;
      const limit = 1000; // 한 번에 가져오는 데이터 수를 늘림
      let hasMore = true;
      const maxRetries = 3; // 재시도 횟수 설정
      
      while (hasMore) {
        let retryCount = 0;
        let success = false;
        
        while (!success && retryCount < maxRetries) {
          try {
            const formatDate = (date) => {
              if (!date) return undefined;
              const d = new Date(date);
              const tzOffset = d.getTimezoneOffset() * 60000;
              const localDate = new Date(d.getTime() - tzOffset);
              return localDate.toISOString().split('T')[0];
            };

            const adjustedEndDate = endDate ? new Date(endDate) : null;
            if (adjustedEndDate) {
              adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
            }

            const response = await getCashPagination(
              page,
              limit,
              search,
              goodsName,
              formatDate(startDate),
              formatDate(adjustedEndDate)
            );
            
            if (response && Array.isArray(response.payments)) {
              allData = [...allData, ...response.payments];
              
              // 진행 상황 로그
              console.log(`Fetched ${allData.length} / ${response.total} records`);
              
              if (allData.length >= response.total) {
                hasMore = false;
              }
              success = true;
            } else {
              throw new Error('Invalid response format');
            }
          } catch (error) {
            retryCount++;
            if (retryCount === maxRetries) {
              console.error(`Failed to fetch page ${page} after ${maxRetries} attempts:`, error);
              throw error;
            }
            // 재시도 전 잠시 대기
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
        page++;
      }

      return allData;
    } catch (error) {
      console.error('전체 결제 데이터 불러오기 실패:', error);
      throw error;
    }
  };

  const handleExcelDownload = async () => {
    try {
      // 다운로드 시작 알림
      alert('데이터를 다운로드하고 있습니다. 잠시만 기다려주세요...');
      
      const allData = await fetchAllData();
      
      if (allData.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      // 메모리 최적화를 위해 청크 단위로 처리
      const chunkSize = 1000;
      const chunks = [];
      
      for (let i = 0; i < allData.length; i += chunkSize) {
        const chunk = allData.slice(i, i + chunkSize).map(item => ({
          '주문번호': item.tid || '-',
          '상품구분': item.goodsName || '-',
          '주문금액': item.amount || '-',
          'ID': item.user?.userid || '-',
          '이름': item.user?.username || '-',
          '휴대폰 번호': item.user?.phoneNumber || '-',
          '결제수단': item.payMethod || '-',
          '결제상태': item.status || '-',
          '결제일시': item.status === '결제 실패' ? '-' : (item.paidAt || '-')
        }));
        chunks.push(chunk);
      }

      // 청크를 하나의 워크시트로 결합
      const ws = XLSX.utils.json_to_sheet([]);
      chunks.forEach((chunk, index) => {
        XLSX.utils.sheet_add_json(ws, chunk, {
          origin: index === 0 ? 'A1' : -1,
          skipHeader: index > 0
        });
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "결제목록");
      
      // 파일 이름에 날짜 추가
      const date = new Date().toISOString().split('T')[0];
      XLSX.writeFile(wb, `결제목록_${date}.xlsx`);
      
      alert('엑셀 다운로드가 완료되었습니다.');
    } catch (error) {
      console.error('엑셀 다운로드 실패:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  const handleItemLimitChange = (limit) => {
    setItemsPerPage(limit);
    setCurrentPage(0);
  };

  const selectedDropdownStyle = {
    height: '36px',
    padding: '4px 14px',
    borderRadius: '12px',
    border: '1px solid #E2E8EF',
    fontFamily: 'Pretendard',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '120px',
  };

  // 정렬 핸들러 (정렬 기준 및 방향 설정)
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    
    // 현재 페이지의 데이터만 정렬
    const currentPageData = [...data].sort((a, b) => {
      const aValue = key.includes('.') 
        ? key.split('.').reduce((obj, k) => obj?.[k], a)
        : a[key];
      const bValue = key.includes('.')
        ? key.split('.').reduce((obj, k) => obj?.[k], b)
        : b[key];

      if (direction === 'ascending') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
    
    setData(currentPageData);
  };

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div style={{ padding: '26px 30px' }}>
        <h1 className="font-bold uppercase" style={{ width: '140px', height: '28px', fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700, textAlign: 'left' }}>
          결제목록
        </h1>

        {/* 검색 및 필터 영역 */}
        <div className="" style={{ margin: '26px 0px 20px 0' }}>
          <div className="flex items-center space-x-3">
            {/* 분류 필터 */}
            <select value={goodsName} onChange={handleCategoryChange} className="w-[100px] h-[36px]" style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '4px 14px', borderRadius: '12px', color: '#A9B6CA'}}>
              <option value="">상품구분</option>
              {uniqueGoodsNames.map((cat, index) => (
                <option key={index} value={cat}>{cat}</option>
              ))}
            </select>

            {/* 검색어 입력 */}
            <div className="flex">
              <input
                type="text"
                placeholder="검색어를 입력하세요"
                value={search}
                onChange={handleSearch}
                className="h-[36px] w-[250px]"
                style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '10px 14px', borderRadius: '12px', color: '#A9B6CA' }}
              />
            </div>

            {/* 기간 필터 */}
            <div 
              className="relative flex items-center h-[36px] w-[250px] bg-white cursor-pointer" 
              style={{ borderRadius: '12px', padding: '0px 8px' }}
              onClick={() => {
                const datePickerInput = document.querySelector('.react-datepicker__input-container input');
                if (datePickerInput) {
                  datePickerInput.click();
                }
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  if (start) {
                    const startOfDay = new Date(start);
                    startOfDay.setHours(0, 0, 0, 0);
                    setStartDate(startOfDay);
                  } else {
                    setStartDate(null);
                  }
                  
                  if (end) {
                    const endOfDay = new Date(end);
                    endOfDay.setHours(23, 59, 59, 999);
                    setEndDate(endOfDay);
                  } else {
                    setEndDate(null);
                  }
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                isClearable={true}
                dateFormat="yyyy-MM-dd"
                placeholderText="기간 입력"
                className="w-full h-full"
                style={{ 
                  fontFamily: 'Pretendard', 
                  fontSize: '14px', 
                  fontWeight: 500,
                  border: 'none',
                  color: '#A9B6CA',
                  cursor: 'pointer',
                  pointerEvents: 'none'
                }}
              />
              <img 
                src={calendarIcon} 
                alt="Calendar Icon" 
                className="absolute right-2 w-7 h-6"
                style={{ pointerEvents: 'none' }}
              />
            </div>
          </div>
        </div>

        {/* 목록 테이블 섹션 */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <div className="text-lg mt-5 mb-7 ml-5">
              <label className="text-xl font-bold text-[#0E111F] mr-5" style={{ fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700 }}>
                목록
              </label>
              <label 
                className="text-sm font-medium text-[#0E111F]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                검색결과 {totalItems}개 | 선택 {selectedRows.length}개 | 현재 페이지 {currentPage + 1 }
              </label>
            </div>

            {/* 액션 버튼들 */}
            <div className="flex justify-end m-5">
              {/* 항목 수 선택 */}
              <div className="relative mr-4">
                <div
                  style={selectedDropdownStyle}
                  onClick={() => setIsItemLimitDropdownOpen(!isItemLimitDropdownOpen)}
                >
                  <span>{`${itemsPerPage}개씩 보기`}</span>
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                </div>
                {isItemLimitDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                    {[5, 10, 20].map((limit) => (
                      <div
                        key={limit}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          handleItemLimitChange(limit);
                          setIsItemLimitDropdownOpen(false);
                        }}
                      >
                        {`${limit}개씩 보기`}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* 엑셀 다운로드 버튼 */}
              <button 
                onClick={handleExcelDownload}
                className="flex items-center justify-center"
                style={{
                  width: '110px',
                  height: '36px',
                  padding: '4px 14px',
                  borderRadius: '12px',
                  backgroundColor: '#666B81',
                  color: '#FFFFFF',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                엑셀 다운로드
              </button>
            </div>
          </div>

          <table className="min-w-full divide-y divide-gray-200 shadow-md">
            <thead className="bg-[#E2E8EF]" style={{ height: '40px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#666B81' }}>
              <tr>
                <th className='text-center w-16'>
                  {/* 모든 항목 선택/해제 체크박스 */}
                  <input 
                    type="checkbox" 
                    onChange={handleSelectAll} 
                    checked={selectedRows.length === data.length} // 모든 항목이 선택되었는지 확인
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-16">No.</th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-28">
                  주문번호
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-28">
                  상품구분
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-40">
                  주문금액
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-24">
                  ID
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-24">
                  이름
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-32">
                  휴대폰 번호
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-24">
                  결제수단
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-24" 
                  onClick={() => handleSort('status')}
                >
                  결제상태<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44" 
                  onClick={() => handleSort('paidAt')}
                >
                  결제일시<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                </th>
              </tr>
            </thead>
            <tbody 
              className="bg-white divide-y divide-gray-200"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}
            >
              {data.map((item, index) => (
                <tr key={item.id} className='hover:bg-gray-100 transition duration-150 ease-in-out h-[40px]'>
                  <td className="whitespace-no-wrap text-center w-16">
                    {/* 각 항목 선택 체크박스 */}
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(item.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectRow(item.id);
                      }}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                  </td>
                  <td className="whitespace-no-wrap text-center" onClick={() => handleRowClick(item.id)}>{index + 1}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.tid}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.goodsName}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.amount}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.user.userid}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.user.username}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.user.phoneNumber}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.payMethod}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.status}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>
                    {item.status === '결제 실패' ? '-' : item.paidAt}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* 페이지네이션 컴포넌트 */}
        {totalItems > 0 && (
          <div className="flex justify-center mt-4">
            <ReactPaginate
              previousLabel={
                <img src={leftCursor} alt="Left Cursor" className="w-4 h-4" />
              }
              nextLabel={
                <img src={rightCursor} alt="Right Cursor" className="w-4 h-4" />
              }
              breakLabel={'...'}
              pageCount={Math.max(1, Math.ceil(totalItems / itemsPerPage))}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination flex space-x-2 items-center'}
              activeClassName={'text-[#3677F6] font-bold'}
              breakClassName={'text-[#A9B6CA]'}
              disabledClassName={'text-[#C5D0DD] cursor-not-allowed'}
              forcePage={Math.min(currentPage, Math.max(0, Math.ceil(totalItems / itemsPerPage) - 1))}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CashList;
