// WelcomeModal.jsx
import React from "react";

const WelcomeModal = ({ text1, text2, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white dark:bg-[#121418] rounded-lg shadow-lg p-6 w-[90%] max-w-md">
      <h2 className="text-2xl font-bold text-center text-gray-800 dark:text-white mb-4">
        {text1}
      </h2>
      <p className="text-gray-600 dark:text-gray-300 text-center mb-6">
        {text2}
      </p>
      <button
        onClick={onClose}
        className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded-lg mt-4"
      >
        닫기
      </button>
    </div>
  </div>
);

export default WelcomeModal;
