import React, { useEffect, useState } from "react";
import Logo from "../logincomponents/Logo";
import LoginOptions from "../logincomponents/LoginOptions";
import styles from "./Welcome.module.css";
import WelcomeModal from "../WelcomeModal";

const Welcome = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalText, setModalText] = useState({ text1: "", text2: "" });

  useEffect(() => {
    const isNew = sessionStorage.getItem("isNew") === "true";
    const deleteAt = sessionStorage.getItem("deletedAt") === "true";

    if (isNew || deleteAt) {
      setIsModalVisible(true);

      if (isNew) {
        setModalText({
          text1: "🎉 Welcome to Pastella!",
          text2: "회원가입을 축하합니다",
        });
      }

      if (deleteAt) {
        setModalText({
          text1: "탈퇴한 회원입니다",
          text2: "ID를 다시 확인해주세요",
        });
      }
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    sessionStorage.setItem("isNew", "false"); // Update to prevent showing again
    sessionStorage.setItem("deletedAt", "false"); // Update to prevent showing again
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className={`min-h-screen ${darkMode ? "bg-[#121418]" : "bg-white"}`}>
      <div className={`${styles.div} dark:bg-[#121418]`}>
        <Logo />
        <div className="fixed bottom-0 w-full dark:bg-[#121418]">
          <LoginOptions />
        </div>
      </div>
      {isModalVisible && (
        <WelcomeModal
          text1={modalText.text1}
          text2={modalText.text2}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Welcome;
