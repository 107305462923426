import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Make sure to install react-toastify
import { useDispatch, useSelector } from "react-redux";
import { addItems } from "./actions";
import GnbAi from "./components/GnbAi";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary
import { CgClose } from "react-icons/cg";
import axios from "axios";
import { NoItemsModal } from "./NoItemsModal";
const Ai = () => {
  const { translations, language } = useContext(LanguageContext);
  const currentLanguage = language === "kor" ? "eng" : "kor";
  const [selectedGender, setSelectedGender] = useState(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items); // Access items from Redux state
  const [filteredItems, setFilteredItems] = useState(items);
  const [page, setPage] = useState(1); // Manage page or data set state
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );
  const [apiItems, setApiItems] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const accessToken = sessionStorage.getItem("accessToken");

  // 현재 언어에 따라 반대 언어 결정
  const getOppositeLanguage = useCallback(() => {
    return currentLanguage;
  }, [currentLanguage]);

  const sampleData = {
    "Give up": "포기하다",
    "Hang in there": "견디다, 버티다",
    "Show up": "나타나다, 출석하다",
    "Get along": "잘 지내다",
    "Break down": "고장 나다, 실패하다",
    "Look after": "돌보다",
    "Figure out": "이해하다, 알아내다",
    "Turn off": "끄다",
    "Turn on": "켜다",
    "Go on": "계속하다",
    "Come back": "돌아오다",
    "Bring up": "언급하다, 키우다",
    "Take off": "이륙하다, 벗다",
    "Put away": "치우다",
    "Pick up": "줍다, 데리러 가다",
    "Drop off": "내려주다",
    "Get over": "극복하다",
    "Run out of": "다 써버리다",
    "Make up": "화해하다, 꾸미다",
    "Look forward to": "기대하다",
    "Catch up": "따라잡다",
    "Cut off": "차단하다",
    "Fill in": "작성하다, 대신하다",
    "Get rid of": "제거하다",
    "Hold on": "기다리다, 잠깐만",
    "Look up": "찾아보다",
    "Make out": "이해하다, 알아차리다",
    "Pass out": "기절하다, 배포하다",
    "Put off": "미루다",
    "Set up": "준비하다, 설치하다",
    "Take care of": "돌보다",
    "Throw away": "버리다",
    "Try on": "입어보다",
    "Wake up": "일어나다",
    "Work out": "운동하다, 해결되다",
    "Carry on": "계속하다",
    "Check in": "체크인하다",
    "Come across": "우연히 만나다",
    "Fall apart": "붕괴되다",
    "Get in": "들어가다",
    "Keep up": "유지하다, 따라가다",
    "Let down": "실망시키다",
    "Look into": "조사하다",
    "Make sure": "확실히 하다",
    "Point out": "지적하다",
    "Pull over": "차를 길가에 세우다",
    "Run into": "우연히 만나다",
    "Stand up": "일어서다",
    "Take off (2)": "출발하다, 이륙하다",
    "Turn down": "거절하다, 볼륨을 낮추다",
  };

  const engList = Object.keys(sampleData).map((key) => ({
    title: key,
  }));

  const korList = Object.values(sampleData).map((value) => ({
    title: value,
  }));

  useEffect(() => {
    const fetchItems = async () => {
      const oppositeLanguage = getOppositeLanguage();
      try {
        const response = await axios.get(
          `${apiUrl}/recent-learnings/random?language=${oppositeLanguage}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.log(response.data);
        setApiItems(response.data);
      } catch (error) {
        console.log("here!");
        const randomItems = pickRandomItems(oppositeLanguage, 5);
        setApiItems(randomItems);
        console.log(randomItems);
        setUniqueItems(
          Array.from(new Set(randomItems.map((item) => item.title))).map(
            (title) => randomItems.find((item) => item.title === title)
          )
        );
        console.log("here2");
      }
    };

    fetchItems();
  }, [
    currentLanguage,
    getOppositeLanguage,
    translations.dataLoadError,
    accessToken,
    navigate,
  ]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);
  const typeList = [
    "구동사",
    "관용구",
    "고정표현",
    "신조어",
    "슬랭",
    "문법적 구조",
    "약어",
    "속담",
    "숙어",
    "단어",
  ];

  const overlayRef = useRef(null); // Ref for the overlay
  const goToVocabList = () => {
    navigate("/aichat", {
      state: { apiItems: uniqueItems },
    });
  };

  const getRandomType = () => {
    const randomIndex = Math.floor(Math.random() * typeList.length);
    return typeList[randomIndex];
  };

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleSelect = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) =>
        prevSelectedGender === value ? null : value
      );
    }
  };

  const handleComplete = () => {
    if (selectedGender) {
      const filtered = items.filter((item) => item.type === selectedGender);
      setFilteredItems(filtered); // Update filtered items
      sessionStorage.setItem("selectedGender", selectedGender);
    } else {
      setFilteredItems(items); // Update filtered items
      sessionStorage.removeItem("selectedGender");
    }
    toggleOverlay(); // Optionally close the overlay
  };

  const loadMoreData = useCallback(() => {
    if (loading || !hasMore) return;

    setLoading(true);

    setTimeout(() => {
      if (page < 100) {
        const newItems = Array(5)
          .fill()
          .map((_, idx) => ({
            item: `Item ${items.length + idx + 1}`,
            type: getRandomType(),
          }));
        // setData((prevData) => [...prevData, ...newItems]);
        dispatch(addItems(newItems));
        setPage((prevPage) => prevPage + 1); // Increment the page number
      } else {
        setHasMore(false);
        toast.info("End of data");
      }
      setLoading(false);
    }, 100);
  }, [loading, hasMore, page, items, dispatch]);

  const lastScrollTopRef = useRef(
    window.pageYOffset || document.documentElement.scrollTop
  );
  const debounceTimerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;

    if (currentScrollTop < lastScrollTopRef.current) {
      clearTimeout(debounceTimerRef.current);

      debounceTimerRef.current = setTimeout(() => {
        loadMoreData();
      }, 100); // Adjust the time (2000 ms = 2 seconds) as needed
    }

    lastScrollTopRef.current = currentScrollTop;
  }, [loadMoreData]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimerRef.current);
    };
  }, [handleScroll]);

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    const storedGender = sessionStorage.getItem("selectedGender");
    if (storedGender) {
      setSelectedGender(storedGender);
    }
    if (items.length < 5) {
      loadMoreData();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const filtered = selectedGender
      ? items.filter((item) => item.type === selectedGender)
      : items;
    setFilteredItems(filtered);
  }, [items, selectedGender]);

  const handleSwipeLeft = () => {
    navigate("/mypage");
  };

  const handleSwipeRight = () => {
    navigate("/express");
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);

  const [uniqueItems, setUniqueItems] = useState([]);

  useEffect(() => {
    const unique = Array.from(new Set(apiItems.map((item) => item.title))).map(
      (title) => apiItems.find((item) => item.title === title)
    );
    setUniqueItems(unique);
  }, [apiItems]);

  console.log(uniqueItems);

  const handleGoAi = () => {
    navigate("/ai", { state: { apiItems: apiItems } });
  };

  const [isModalVisible, setModalVisible] = useState(false); // Modal visibility state

  const excludedTitlesRef = useRef(new Set()); // Persistent exclusion list

  const handleRemoveItem = async (itemToRemove) => {
    // Add the item's title to the exclusion list
    excludedTitlesRef.current.add(itemToRemove.title);

    // Remove the item from uniqueItems
    setUniqueItems((prevItems) =>
      prevItems.filter((item) => item !== itemToRemove)
    );

    // Helper function to find a suitable replacement
    const findReplacement = (itemsToCheck, currentItems) => {
      const currentTitles = new Set(currentItems.map((item) => item.title));
      return itemsToCheck.find(
        (newItem) =>
          !currentTitles.has(newItem.title) && // Exclude items already in uniqueItems
          !excludedTitlesRef.current.has(newItem.title) // Exclude items in exclusion list
      );
    };

    // Fetch a new batch of items
    const fetchNewItems = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/recent-learnings/random?language=${getOppositeLanguage()}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Failed to fetch new items:", error);
        return [];
      }
    };

    // Try to find a replacement item
    let newBatch = await fetchNewItems();
    let replacement = findReplacement(newBatch, uniqueItems);

    // Retry fetching if no suitable replacement is found
    if (!replacement) {
      console.log("Retrying fetch for a suitable replacement...");
      newBatch = await fetchNewItems();
      replacement = findReplacement(newBatch, uniqueItems);
    }

    if (replacement) {
      excludedTitlesRef.current.add(replacement.title);
      setUniqueItems((prevItems) => [...prevItems, replacement]);
    } else {
      console.log("No suitable replacement found after two attempts.");
      setModalVisible(true); // Show modal
      setTimeout(() => setModalVisible(false), 1000); // Auto-close after 1 second
    }
  };

  const pickRandomItems = (language, count) => {
    const list = language === "eng" ? engList : korList;

    // Shuffle the list and pick the first 'count' items
    const shuffledList = list.sort(() => Math.random() - 0.5);
    return shuffledList.slice(0, count);
  };

  return (
    <div
      id="swipe-container"
      className="flex min-h-screen justify-center w-full bg-[#faf8f6] "
    >
      <div className="relative w-[100%] bg-[#faf8f6] dark:bg-[#121418]">
        {/* Navigation Bar */}
        <GnbAi></GnbAi>
        {/* Main Content */}
        <div className="relative w-full h-full flex justify-center">
          <div className="flex-grow relative w-full">
            <div className="absolute left-[16px] top-[77px] flex flex-row items-center justify-start gap-[5px]">
              <div className="flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch text-[16px] leading-[100%] font-sans font-bold text-[#25272f] dark:text-[#fff]">
                  {translations.ai1}
                </div>
                <div className="text-[12px] leading-[100%] font-sans font-medium text-[#000] dark:text-[#fff] whitespace-nowrap">
                  {translations.ai2}
                </div>
              </div>
            </div>

            <div className="relative left-[3vw] top-[129px] w-[94vw] flex flex-col rounded-[20px] mb-[200px]">
              {uniqueItems.map((item, index) => (
                <div
                  key={index}
                  className="self-stretch flex flex-row items-center justify-between py-[13px] px-[14px] bg-[#fff] dark:bg-[#22232a] border-b border-#e9ecef"
                >
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-[5px] h-[5px] shrink-0 bg-[#f67239] rounded-full"></div>
                    <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] text-justify whitespace-nowrap overflow-hidden text-ellipsis max-w-[70vw]">
                      {item.title}
                    </div>
                  </div>
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-justify whitespace-nowrap">
                    <div className="relative w-[30px] h-[30px] shrink-0">
                      <CgClose
                        size={28}
                        className=""
                        color={darkMode ? "white" : "black"}
                        onClick={() => {
                          handleRemoveItem(item);
                        }}
                      ></CgClose>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex flex-row items-center justify-end">
                <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] dark:text-[#fff] whitespace-nowrap">
                  {translations.ai3}
                </div>
                <div className="w-[32px] h-[32px] shrink-0 flex flex-row items-center justify-center p-[8px]">
                  <img
                    width="32"
                    height="32"
                    src="VectorI20_198;365_5132.png"
                    onClick={handleGoAi}
                  ></img>
                </div>
              </div>
            </div>

            <div className="absolute left-0 top-[2vh] w-[360px] h-[50px]">
              <div className="absolute left-[16px] top-[7px] w-[92vw] flex flex-row items-center justify-between">
                <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] text-center whitespace-nowrap">
                  AI Chat
                </div>
                {darkMode ? (
                  <LanguageSwitcher2></LanguageSwitcher2>
                ) : (
                  <LanguageSwitcher></LanguageSwitcher>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="fixed bottom-[90px] w-full pb-[20px] px-[16px] z-40">
          <button
            className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
            onClick={() => goToVocabList()}
          >
            {translations.ai4}
          </button>
        </div>
      </div>
      {/* Modal */}
      <NoItemsModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        language={language}
      />
    </div>
  );
};

export default Ai;
