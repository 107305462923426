import { useEffect, useRef } from "react";

const UseSwipe = (onSwipeLeft, onSwipeRight, isSwipeEnabled = true) => {
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const handleTouchStart = (e) => {
    if (!isSwipeEnabled) return; // Ignore swipe if disabled
    touchStartX.current = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e) => {
    if (!isSwipeEnabled) return; // Ignore swipe if disabled
    touchEndX.current = e.changedTouches[0].screenX;
    handleSwipe();
  };

  const handleSwipe = () => {
    if (!isSwipeEnabled) return; // Prevent swipe actions if disabled
    if (touchStartX.current - touchEndX.current > 170) {
      onSwipeLeft();
    } else if (touchEndX.current - touchStartX.current > 170) {
      onSwipeRight();
    }
  };

  useEffect(() => {
    const container = document.getElementById("swipe-container");
    if (!container) return;

    container.addEventListener("touchstart", handleTouchStart);
    container.addEventListener("touchend", handleTouchEnd);

    return () => {
      container.removeEventListener("touchstart", handleTouchStart);
      container.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isSwipeEnabled]); // Re-attach listeners when isSwipeEnabled changes
};

export default UseSwipe;
