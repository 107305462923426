import React, { useState, useContext } from "react";
import TextField from "../logincomponents/PhoneVerification";
import Title from "../logincomponents/Title";
import BBtn from "../logincomponents/BBtn";
import OptionalTextField from "../logincomponents/OptionalTextField";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import MustTextField from "../logincomponents/MustTextField";
import { LanguageContext } from "../LanguageContext";

const ID = () => {
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { name, phoneNumber } = location.state || {};
  const [userId, setUserId] = useState(""); // userId 상태 추가
  const [error, setError] = useState(""); // error 상태 추가
  const navigate = useNavigate();
  const { translations, language } = useContext(LanguageContext);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (!name || !phoneNumber) {
      setError("본인인증을 완료해주세요.");
      return;
    }

    // 전화번호에서 하이픈 제거 및 형식 변경
    let formattedPhoneNumber = phoneNumber.replace(/-/g, "");
    if (formattedPhoneNumber.startsWith("010")) {
      formattedPhoneNumber = "+82" + formattedPhoneNumber.slice(1);
    }

    try {
      console.log(
        "API URL:",
        `${process.env.REACT_APP_API_URL}/auth/find-userid`
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/find-userid`,
        {
          name: name,
          phoneNumber: formattedPhoneNumber,
        }
      );

      setUserId(response.data.userid);
      setError("");
      navigate("/lost-id2", {
        state: {
          userId: response.data.userid,
          phoneNumber: "0" + formattedPhoneNumber.slice(3),
          name: name,
        },
      });
    } catch (err) {
      setError("아이디를 찾을 수 없습니다. 다시 시도해주세요.");
      setUserId("");
    }
  };

  const openNiceWindow = async () => {
    sessionStorage.setItem("registerwhere", "id");
    const { form } = document;
    const res = await axios.get(`https://pastellapp.com/api/auth/nice`);

    if (form && res.data) {
      const { tokenVersionId, encData, integrityValue } = res.data;
      form.enc_data.value = encData;
      form.token_version_id.value = tokenVersionId;
      form.integrity_value.value = integrityValue;
      form.submit();
    }
  };

  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop={translations.pw0}
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
          to="/login1"
        />
        <FormForm onSubmit={handleSubmit}>
          {name ? (
            <MustTextField
              prop="이름"
              prop1="본인인증을 진행해주세요."
              value={name}
              style={{ backgroundColor: "#E2E8EF" }}
            />
          ) : (
            <div
              onClick={openNiceWindow}
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                textAlign: "center",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                margin: "20px auto", // To center horizontally
                maxWidth: "200px", // Optional, adjust as needed
              }}
            >
              {translations.pw3}
            </div>
          )}
          {phoneNumber ? (
            <MustTextField
              prop="휴대폰번호"
              prop1="본인인증을 진행해주세요."
              value={phoneNumber}
              style={{ backgroundColor: "#E2E8EF" }}
            />
          ) : (
            ""
          )}
          {error && <p style={{ color: "red" }}>{error}</p>}
        </FormForm>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BBtn prop={translations.pw4} onClick={handleSubmit} />
      </div>
      <form
        name="form"
        id="form"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
    </ReactiveOuter>
  );
};

export default ID;
