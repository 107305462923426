import React, { useState, useEffect } from 'react';
import Navigation from '../../components/admin/Navigation';
import TermsAndConditions from '../../components/admin/TermsAndConditions';
import PersonalInformation from '../../components/admin/PersonalInformation';
import axios from 'axios';
import API_BASE_URL from '../../config';
import trashIcon from '../../assets/admin/icons/trash.png';

const AppEditor = () => {

  const bannerid = 1;

  const [appData, setAppData] = useState({
    appName: '',
    appNameEn: '',
    version: '',
    phoneNumber: '',
    email: '',
    companyName: '',
    representativeName: '',
    location: '',
    businessNumber: '',
    salesNumber: '',
    freeDay: '',
  });

  const [banners, setBanners] = useState([
    { id: 1, image: '', link: '', file: null },
    { id: 2, image: '', link: '', file: null },
    { id: 3, image: '', link: '', file: null },
    { id: 4, image: '', link: '', file: null },
    { id: 5, image: '', link: '', file: null },
  ]);

  useEffect(() => {
    fetchAppSettings();
  }, []);

  const fetchAppSettings = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/appsettings/${bannerid}`);
      const data = response.data;
      setAppData({
        appName: data.appName || '',
        appNameEn: data.appNameEn || '',
        version: data.version || '',
        phoneNumber: data.phoneNumber || '',
        email: data.email || '',
        companyName: data.companyName || '',
        representativeName: data.representativeName || '',
        location: data.location || '',
        businessNumber: data.businessNumber || '',
        salesNumber: data.salesNumber || '',
        freeDay: data.freeDay || '',
      });
      
      const newBanners = [];
      for (let i = 1; i <= 5; i++) {
        newBanners.push({
          id: i,
          image: data[`bannerImage${i}`] || '',
          link: data[`bannerLink${i}`] || '',
          file: null,
        });
      }
      setBanners(newBanners);
    } catch (error) {
      console.error('앱 설정을 불러오는 데 실패했습니다:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAppData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (id, e) => {
    const file = e.target.files[0];
    if (file) {
      setBanners(prev => prev.map(banner =>
        banner.id === id ? { ...banner, file: file } : banner
      ));
    }
  };

  const handleLinkInputChange = (id, value) => {
    setBanners(prev => prev.map(banner => 
      banner.id === id ? { ...banner, link: value } : banner
    ));
  };

  const handleSaveBanner = async (id) => {
    const banner = banners.find(b => b.id === id);
    if (!banner) return;

    try {
      const formData = new FormData();
      if (banner.file) {
        formData.append('file', banner.file);
      } else if (banner.image) {
        formData.append('image', banner.image);
      }
      formData.append('link', banner.link);

      const response = await axios.patch(`${API_BASE_URL}/appsettings/${bannerid}/banners/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        alert('배너가 성공적으로 저장되었습니다.');
        fetchAppSettings();
      }
    } catch (error) {
      console.error('배너 업데이트 실패:', error);
      
      if (!banner.file && banner.image) {
        alert(`
          오류가 발생했습니다. 
          링크만 변경하는 것은 현재 지원되지 않습니다. 
          다음 절차를 따라주시기 바랍니다:

          1. 먼저 링크를 삭제해 주십시오.
          2. 이미지 파일을 다시 선택해 주십시오.
          3. 새로운 링크를 입력해 주십시오.
          4. '저장' 버튼을 눌러 변경사항을 적용해 주십시오.

          불편을 끼쳐 대단히 죄송합니다. 
          귀하의 협조에 감사드립니다.
        `);
      } else {
        alert('배너 업데이트에 실패했습니다. 다시 시도해 주십시오.');
      }
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/appsettings/${bannerid}`, {
        ...appData,
      });

      if (response.status === 200) {
        alert('설정이 저장되었습니다.');
        fetchAppSettings();
      } else {
        throw new Error('서버 응답이 올바르지 않습니다.');
      }
    } catch (error) {
      console.error('설정 저장에 실패했습니다:', error);
      alert('설정 저장에 실패했습니다.');
    }
  };

  const handleDelete = async (id, type) => {
    try {
      await axios.delete(`${API_BASE_URL}/appsettings/${bannerid}/banners/${id}`);
      alert(`배너가 삭제되었습니다.`);
      fetchAppSettings(); // 업데이트된 데이터 다시 불러오기
    } catch (error) {
      console.error(`배너 ${type} 삭제 실패:`, error);
      alert(`배너 ${type} 삭제에 실패했습니다.`);
    }
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      <div className="flex justify-between items-center mb-5">
        <h1 
          className="text-2xl font-bold text-left"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          앱 설정
        </h1>
        <button 
          className="w-[100px] h-[48px] bg-[#3677F6] text-white"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 700,
            borderRadius: '12px'
          }}
          onClick={handleSave}
        >
          저장하기
        </button>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '#0E111F',
          }}
        >
          기본정보
        </h2>
        <div className="grid grid-cols-5 gap-4">
          {Object.entries(appData).map(([key, value]) => (
            <div key={key} className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '#0E111F',
                }}
              >
                {key === 'appName' ? '앱 이름' :
                 key === 'appNameEn' ? '영문' :
                 key === 'version' ? '버전' :
                 key === 'phoneNumber' ? '전화번호' :
                 key === 'email' ? '전자우편' :
                 key === 'companyName' ? '회사명' :
                 key === 'representativeName' ? '대표' :
                 key === 'location' ? '소재지' :
                 key === 'businessNumber' ? '사업자번호' :
                 key === 'salesNumber' ? '통신판매업' :
                 key === 'freeDay' ? '무료 이용 기간(일)' : ''}
              </label>
              <input
                type={key === 'freeDay' ? 'number' : 'text'}
                name={key}
                value={value}
                onChange={handleInputChange}
                className="w-full bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '#0E111F',
          }}
        >
          약관
        </h2>
        <div className="space-y-4">
          <div>
            <h3 className="font-bold mb-2">이용약관</h3>
            <div className="border border-[#E2E8EF] rounded-lg p-4 max-h-[300px] overflow-y-auto">
              <TermsAndConditions />
            </div>
          </div>
          <div>
            <h3 className="font-bold mb-2">개인정보 처리방침</h3>
            <div className="border border-[#E2E8EF] rounded-lg p-4 max-h-[300px] overflow-y-auto">
              <PersonalInformation />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '#0E111F',
          }}
        >
          배너 관리
        </h2>
        <div className="space-y-4">
          {banners.map((banner) => (
            <div key={banner.id} className="flex gap-4 items-center">
              <span className="w-16 text-center">{`배너 ${banner.id}`}</span>
              <div className="flex-1 relative">
                <input
                  type="text"
                  value={banner.file ? banner.file.name : banner.image ? banner.image.split('/').pop() : ''}
                  readOnly
                  className="w-full bg-white border border-[#E2E8EF] pr-24"
                  style={{
                    height: '48px',
                    padding: '18px 14px',
                    borderRadius: '12px',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                />
                {(banner.image || banner.file) && (
                  <img
                    src={trashIcon}
                    alt="삭제"
                    className="absolute right-24 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer"
                    onClick={() => handleDelete(banner.id, 'image')}
                  />
                )}
                <input
                  type="file"
                  id={`file-${banner.id}`}
                  className="hidden"
                  onChange={(e) => handleFileChange(banner.id, e)}
                />
                <label
                  htmlFor={`file-${banner.id}`}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 w-[80px] h-[36px] bg-[#3677F6] text-white flex items-center justify-center"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 700,
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                >
                  {banner.image || banner.file ? '수정' : '첨부'}
                </label>
              </div>
              <div className="flex-1 relative">
                <input
                  type="text"
                  value={banner.link}
                  onChange={(e) => handleLinkInputChange(banner.id, e.target.value)}
                  placeholder="링크를 입력해주세요"
                  className="w-full bg-white border border-[#E2E8EF] pr-24"
                  style={{
                    height: '48px',
                    padding: '18px 14px',
                    borderRadius: '12px',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                />
                {banner.link && (
                  <img
                    src={trashIcon}
                    alt="삭제"
                    className="absolute right-24 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer"
                    onClick={() => handleDelete(banner.id, 'link')}
                  />
                )}
              </div>
              <button
                onClick={() => handleSaveBanner(banner.id)}
                className="w-[80px] h-[48px] bg-[#3677F6] text-white flex items-center justify-center"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  borderRadius: '12px',
                  cursor: 'pointer',
                }}
              >
                저장
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppEditor;
