import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navigation from '../../components/admin/Navigation';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/admin/icons/calendar.png';
import handleSortIcon from '../../assets/admin/icons/handleSortIcon.png';
import leftCursor from '../../assets/admin/icons/leftCursor.png';
import rightCursor from '../../assets/admin/icons/rightCursor.png';
import { ChevronDownIcon } from '@heroicons/react/solid';

const ErrorList = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [isPinned, setIsPinned] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [isItemLimitDropdownOpen, setIsItemLimitDropdownOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  useEffect(() => {
    fetchErrorData();
  }, [currentPage, itemsPerPage, isPinned, title, startDate, endDate, sortConfig]);

  const fetchErrorData = async () => {
    try {
      const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        const tzOffset = d.getTimezoneOffset() * 60000;
        const localDate = new Date(d.getTime() - tzOffset);
        return localDate.toISOString().split('T')[0];
      };

      // endDate를 다음날로 설정
      const adjustedEndDate = endDate ? new Date(endDate) : null;
      if (adjustedEndDate) {
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
      }

      const response = await axios.get(`${API_BASE_URL}/errorreports/filter`, {
        params: {
          page: currentPage + 1,
          limit: itemsPerPage,
          isPinned: isPinned,
          title: title,
          startDate: formatDate(startDate),
          endDate: formatDate(adjustedEndDate)
        }
      });

      let currentPageData = [...response.data.errorreports];
      if (sortConfig.key) {
        currentPageData = currentPageData.sort((a, b) => {
          const aValue = sortConfig.key.includes('.') 
            ? sortConfig.key.split('.').reduce((obj, key) => obj?.[key], a)
            : a[sortConfig.key];
          const bValue = sortConfig.key.includes('.')
            ? sortConfig.key.split('.').reduce((obj, key) => obj?.[key], b)
            : b[sortConfig.key];

          if (sortConfig.direction === 'ascending') {
            return aValue > bValue ? 1 : -1;
          } else {
            return aValue < bValue ? 1 : -1;
          }
        });
      }

      setData(currentPageData);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error('오류 데이터 가져오기 실패:', error);
    }
  };

  const handleCategoryChange = (event) => {
    setIsPinned(event.target.value);
    setCurrentPage(0);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setCurrentPage(0);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map(item => item.id));
    }
  };

  const handleRowClick = (id) => {
    navigate(`/error-editor/${id}`);
  };

  const handleItemLimitChange = (limit) => {
    setItemsPerPage(limit);
    setCurrentPage(0);
    setIsItemLimitDropdownOpen(false);
  };

  const selectedDropdownStyle = {
    height: '36px',
    padding: '4px 14px',
    borderRadius: '12px',
    border: '1px solid #E2E8EF',
    fontFamily: 'Pretendard',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '120px',
  };

  // 정렬 핸들러 (정렬 기준 및 방향 설정)
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      alert('삭제할 항목을 선택해주세요.');
      return;
    }

    if (window.confirm('선택한 항목을 삭제하시겠습니까?')) {
      try {
        for (const id of selectedRows) {
          await axios.delete(`${API_BASE_URL}/errorreports/${id}`);
        }
        setSelectedRows([]);
        fetchErrorData();
      } catch (error) {
        console.error('삭제 실패:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div style={{ padding: '26px 30px' }}>
        
        {/* 제목 표시 */}
        <h1 className="font-bold uppercase" style={{ width: '140px', height: '28px', fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700, textAlign: 'left' }}>
          오류신고
        </h1>

        {/* 검색 및 필터 영역 */}
        <div className="" style={{ margin: '26px 0px 20px 0' }}>
          <div className="flex items-center space-x-3">
            {/* 분류 필터 */}
            <select value={isPinned} onChange={handleCategoryChange} className="w-[100px] h-[36px]" style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '4px 14px', borderRadius: '12px', color: '#A9B6CA'}}>
              <option value="">상태</option>
              <option value="true">반영</option>
              <option value="false">미반영</option>
            </select>

            {/* 검색어 입력 */}
            <div className="flex">
              <input
                type="text"
                placeholder="제목을 입력하세요"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="h-[36px] w-[250px]"
                style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '10px 14px', borderRadius: '12px', color: '#A9B6CA' }}
              />
            </div>

            {/* 기간 필터 */}
            <div 
              className="relative flex items-center h-[36px] w-[250px] bg-white cursor-pointer" 
              style={{ borderRadius: '12px', padding: '0px 8px' }}
              onClick={() => {
                const datePickerInput = document.querySelector('.react-datepicker__input-container input');
                if (datePickerInput) {
                  datePickerInput.click();
                }
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  if (start) {
                    const startOfDay = new Date(start);
                    startOfDay.setHours(0, 0, 0, 0);
                    setStartDate(startOfDay);
                  } else {
                    setStartDate(null);
                  }
                  
                  if (end) {
                    const endOfDay = new Date(end);
                    endOfDay.setHours(23, 59, 59, 999);
                    setEndDate(endOfDay);
                  } else {
                    setEndDate(null);
                  }
                  setCurrentPage(0);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                isClearable={true}
                dateFormat="yyyy-MM-dd"
                placeholderText="기간 입력"
                className="w-full h-full"
                style={{ 
                  fontFamily: 'Pretendard', 
                  fontSize: '14px', 
                  fontWeight: 500,
                  border: 'none',
                  color: '#A9B6CA',
                  cursor: 'pointer',
                  pointerEvents: 'none'
                }}
              />
              <img 
                src={calendarIcon} 
                alt="Calendar Icon" 
                className="absolute right-2 w-7 h-6"
                style={{ pointerEvents: 'none' }}
              />
            </div>
          </div>
        </div>

        {/* 목록 테이블 섹션 */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <div className="text-lg mt-5 mb-7 ml-5">
              <label className="text-xl font-bold text-[#0E111F] mr-5" style={{ fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700 }}>
                목록
              </label>
              <label 
                className="text-sm font-medium text-[#0E111F]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                검색결과 {totalItems}개 | 선택 {selectedRows.length}개 | 현재 페이지 {currentPage + 1 }
              </label>
            </div>

            {/* 액션 버튼들 */}
            <div className="flex justify-end m-5">
              {/* 항목 수 선택 */}
              <div className="relative mr-4">
                <div
                  style={selectedDropdownStyle}
                  onClick={() => setIsItemLimitDropdownOpen(!isItemLimitDropdownOpen)}
                >
                  <span>{`${itemsPerPage}개씩 보기`}</span>
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                </div>
                {isItemLimitDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                    {[5, 10, 20].map((limit) => (
                      <div
                        key={limit}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleItemLimitChange(limit)}
                      >
                        {`${limit}개씩 보기`}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* 선택 삭제 버튼 */}
              <button 
                onClick={handleDelete} 
                className="p-2 rounded-md text-[#383C4B]"
                style={{
                  width: '100px',
                  height: '36px',
                  borderRadius: '12px',
                  background: '#C5D0DD',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                선택 삭제
              </button>
            </div>
          </div>

          <table className="min-w-full divide-y divide-gray-200 shadow-md">
            <thead className="bg-[#E2E8EF]" style={{ height: '40px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#666B81' }}>
              <tr>
                <th className='text-center w-16'>
                  <input 
                    type="checkbox" 
                    onChange={handleSelectAll} 
                    checked={selectedRows.length === data.length}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-16">
                  No.
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-22">
                  문의유형
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-22">
                  언어
                </th>
                <th 
                  className="text-left font-medium text-gray-500 tracking-wider cursor-pointer w-109"
                  onClick={() => handleSort('title')}
                >
                  제목<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                </th>
                <th className="text-center font-medium text-gray-500 w-30">
                  위치
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44"
                  onClick={() => handleSort('createdAt')}
                >
                  신고일시<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-44">
                  상태
                </th>
              </tr>
            </thead>
            <tbody 
              className="bg-white divide-y divide-gray-200"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}
            >
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={item.id} className='hover:bg-gray-100 transition duration-150 ease-in-out h-[40px] cursor-pointer' onClick={() => handleRowClick(item.id)}>
                    <td className="whitespace-no-wrap text-center w-16">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleSelectRow(item.id);
                        }}
                        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </td>
                    <td className="whitespace-no-wrap text-center">
                      {index + 1}
                    </td>
                    <td className="whitespace-no-wrap text-center">
                      {item.category}
                    </td>
                    <td className="whitespace-no-wrap text-center">
                      {item.language}
                    </td>
                    <td className="whitespace-no-wrap">
                      {item.title}
                    </td>
                    <td className="whitespace-no-wrap text-center">
                      {item.location}
                    </td>
                    <td className="whitespace-no-wrap text-center">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </td>
                    <td className="whitespace-no-wrap text-center">
                      {item.isPinned ? '반영' : '미반영'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center py-4">데이터가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* 페이지네이션 컴포넌트 */}
        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel={
              <img src={leftCursor} alt="Left Cursor" className="w-4 h-4" />
            }
            nextLabel={
              <img src={rightCursor} alt="Right Cursor" className="w-4 h-4" />
            }
            breakLabel={'...'}
            pageCount={Math.ceil(totalItems / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination flex space-x-2 items-center'}
            activeClassName={'text-[#3677F6] font-bold'}
            breakClassName={'text-[#A9B6CA]'}
            disabledClassName={'text-[#C5D0DD] cursor-not-allowed'}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorList;