export const ProgressBar = ({ totalSections = 5, filledSections }) => {
  const gapWidth = 4;

  return (
    <div className="w-full h-1 flex z-40">
      {Array.from({ length: totalSections }).map((_, index) => (
        <div
          key={index}
          className={`h-full ${
            index < filledSections ? "bg-blue-500" : "bg-gray-300"
          }`}
          style={{
            flex: `1 0 calc((100% - ${
              (totalSections - 1) * gapWidth
            }px) / ${totalSections})`,

            marginRight: index < totalSections - 1 ? `${gapWidth}px` : "0",
          }}
        />
      ))}
    </div>
  );
};
