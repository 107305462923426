import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { setManageID, setType, setImageUrl, setWhere } from "./actions";
import { IoIosArrowBack } from "react-icons/io";
import { CgSortAz } from "react-icons/cg";
import BeatLoader from "react-spinners/BeatLoader";
import { FaTimes } from "react-icons/fa";
import { useDifficulty } from "./DifficultyContext";

const BookMark = () => {
  const dispatch = useDispatch();
  const { difficulty, setDifficulty } = useDifficulty();
  const [selectedGender, setSelectedGender] = useState(
    sessionStorage.getItem("bookmarkFilter") ?? null
  );
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const { translations, language } = useContext(LanguageContext);
  const oppositeLanguage = language === "kor" ? "eng" : "kor";
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]); // Initialize state for storing results
  const [data3, setData3] = useState({}); // Initialize state for storing results
  const [data4, setData4] = useState({}); // Initialize state for storing results
  const [data5, setData5] = useState({}); // Initialize state for storing results
  const [filteredData, setFilteredData] = useState([]); // Filtered data to display
  const [isLoading, setIsLoading] = useState(true);
  const setwhere = useSelector((state) => state.setWhere);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );
  const [sortConfig, setSortConfig] = useState({
    key: "latest",
    direction: "ascending",
  });
  const [sortedData, setSortedData] = useState([]);

  const [visibleItems, setVisibleItems] = useState(10); // Start by showing 10 items
  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 10); // Load 10 more items on each click
  };
  const [selectedKeys, setSelectedKeys] = useState([]);
  const overlayRef = useRef(null); // Ref for the overlay
  const typeList = ["MBTI", "Persona", "Situation", "Psychology", "Expression"];
  const apiUrl = process.env.REACT_APP_API_URL;
  const accessToken = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const handleGoBack = () => {
    sessionStorage.removeItem("bookmarkFilter");
    if (sessionStorage.getItem("whereBookmark") === "home") {
      sessionStorage.removeItem("whereBookmark");
      navigate("/home");
    } else {
      sessionStorage.removeItem("whereBookmark");
      navigate("/study");
    }
  };

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleSelect = (value) => {
    setSelectedGenders((prevSelectedGenders) => {
      if (prevSelectedGenders.includes(value)) {
        // If the value is already selected, remove it
        return prevSelectedGenders.filter((item) => item !== value);
      } else {
        // Otherwise, add the value
        return [...prevSelectedGenders, value];
      }
    });
  };

  const handleSelect2 = (value) => {
    setSelectedKeys((prevSelectedKeys) => {
      // Initialize the new array based on the previous state
      const newSelectedKeys = [...prevSelectedKeys];

      // Determine the opposite language
      const oppositeLanguage = language === "kor" ? "eng" : "kor";

      // First, find the secondLevelChild that contains the title matching the current language
      const secondLevelChild = data3.children
        .flatMap((firstLevelChild) => firstLevelChild.children)
        .find((child) =>
          child.translations.some(
            (translation) =>
              translation.language === language.toLowerCase() &&
              translation.name === value
          )
        );

      if (secondLevelChild) {
        // Find the title in the current language
        const currentLanguageTitle = secondLevelChild.translations.find(
          (translation) => translation.language === language.toLowerCase()
        )?.name;

        // Find the title in the opposite language
        const oppositeLanguageTitle = secondLevelChild.translations.find(
          (translation) =>
            translation.language === oppositeLanguage.toLowerCase()
        )?.name;

        // Add the titles to the newSelectedKeys array if they are not already present
        if (
          currentLanguageTitle &&
          oppositeLanguageTitle &&
          newSelectedKeys.includes(currentLanguageTitle) &&
          newSelectedKeys.includes(oppositeLanguageTitle)
        ) {
          // Remove both titles
          return newSelectedKeys.filter(
            (key) =>
              key !== currentLanguageTitle && key !== oppositeLanguageTitle
          );
        } else {
          // Add the titles if they are not already present
          if (
            currentLanguageTitle &&
            !newSelectedKeys.includes(currentLanguageTitle)
          ) {
            newSelectedKeys.push(currentLanguageTitle);
          }
          if (
            oppositeLanguageTitle &&
            !newSelectedKeys.includes(oppositeLanguageTitle)
          ) {
            newSelectedKeys.push(oppositeLanguageTitle);
          }
        }
      }

      return newSelectedKeys;
    });
  };

  const handleSelect3 = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) =>
        prevSelectedGender === value ? null : value
      );
    }
  };

  useEffect(() => {
    if (selectedGender === "Psychology") {
      sessionStorage.setItem("bookmarkFilter", "Psychology");
      const psychologyItems = sortedData.filter((item) => item.psychologys);
      setFilteredData(psychologyItems);
    } else if (selectedGender === "MBTI") {
      sessionStorage.setItem("bookmarkFilter", "MBTI");
      const psychologyItems = sortedData.filter(
        (item) => item.customizes && item.mbti
      );
      setFilteredData(psychologyItems);
    } else if (selectedGender === "Persona") {
      sessionStorage.setItem("bookmarkFilter", "Persona");
      const psychologyItems = sortedData.filter(
        (item) => item.customizes && item.age
      );
      setFilteredData(psychologyItems);
    } else if (selectedGender === "Situation") {
      sessionStorage.setItem("bookmarkFilter", "Situation");
      const psychologyItems = sortedData.filter((item) => item.situations);
      setFilteredData(psychologyItems);
    } else if (selectedGender === "Expression") {
      sessionStorage.setItem("bookmarkFilter", "Expression");
      const psychologyItems = sortedData.filter((item) => item.expressions);
      setFilteredData(psychologyItems);
    } else {
      setFilteredData(sortedData);
    }
  }, [sortedData]);

  const handleComplete = () => {
    toggleOverlay();

    if (selectedGender === "Psychology") {
      sessionStorage.setItem("bookmarkFilter", "Psychology");
      const tagIds = selectedGenders.join(",");
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${apiUrl}/bookmarks/filter?itemType=psychology&tagIds=${tagIds}&page=1&limit=1000`,
            {
              method: "GET", // Specify the HTTP method (default is GET, but good practice to include it explicitly)
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json", // Include other headers if needed
              },
            }
          );

          const fetchedLis = await response.json();
          console.log("here ", fetchedLis.results);
          if (response.ok) {
            const fetchItemTitles = async () => {
              const results = []; // Temporary array to accumulate results

              // Step 1: Iterate over the results array and extract both itemId and itemType
              for (const item of fetchedLis.results) {
                console.log(item);
                const itemId = item.itemId;
                const type = item.itemType;
                let url;
                if (type === "customize") {
                  url = `${apiUrl}/customize/manage/${itemId}`;
                } else if (type === "expression") {
                  url = `${apiUrl}/expressions/manage/${itemId}`;
                } else if (type === "") {
                } else {
                  url = `${apiUrl}/${type}/manage/${itemId}`;
                }

                try {
                  // Step 2: Use both itemType and itemId in the API URL
                  const response = await fetch(url);

                  if (response.ok) {
                    const resultData = await response.json();
                    resultData.level = item.level;
                    // Step 3: Accumulate the resultData in the results array
                    results.push(resultData);
                  } else {
                    console.error(
                      `Error fetching item with ID ${itemId}:`,
                      response.status
                    );
                  }
                } catch (error) {}
              }

              // Step 4: Once all items are fetched, update the data2 state

              setFilteredData(results);
            };
            fetchItemTitles();
          }
        } catch (error) {}
      };
      fetchData();
    } else if (selectedGender === "MBTI") {
      sessionStorage.setItem("bookmarkFilter", "MBTI");
      const tagIds = selectedGenders.join(",");
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${apiUrl}/bookmarks/filter?itemType=customize&mbtiOrPersona=mbti&tagIds=${tagIds}&page=1&limit=1000`,
            {
              method: "GET", // Specify the HTTP method (default is GET, but good practice to include it explicitly)
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json", // Include other headers if needed
              },
            }
          );

          const fetchedLis = await response.json();
          console.log("here ", fetchedLis.results);
          if (response.ok) {
            const fetchItemTitles = async () => {
              const results = []; // Temporary array to accumulate results

              // Step 1: Iterate over the results array and extract both itemId and itemType
              for (const item of fetchedLis.results) {
                console.log(item);
                const itemId = item.itemId;
                const type = item.itemType;
                let url;
                if (type === "customize") {
                  url = `${apiUrl}/customize/manage/${itemId}`;
                } else if (type === "expression") {
                  url = `${apiUrl}/expressions/manage/${itemId}`;
                } else if (type === "") {
                } else {
                  url = `${apiUrl}/${type}/manage/${itemId}`;
                }

                try {
                  // Step 2: Use both itemType and itemId in the API URL
                  const response = await fetch(url);

                  if (response.ok) {
                    const resultData = await response.json();
                    resultData.level = item.level;
                    // Step 3: Accumulate the resultData in the results array
                    results.push(resultData);
                  } else {
                    console.error(
                      `Error fetching item with ID ${itemId}:`,
                      response.status
                    );
                  }
                } catch (error) {}
              }

              // Step 4: Once all items are fetched, update the data2 state

              setFilteredData(results);
            };
            fetchItemTitles();
          }
        } catch (error) {}
      };
      fetchData();
    } else if (selectedGender === "Persona") {
      sessionStorage.setItem("bookmarkFilter", "Persona");
      const tagIds = selectedGenders.join(",");
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${apiUrl}/bookmarks/filter?itemType=customize&mbtiOrPersona=persona&tagIds=${tagIds}&page=1&limit=1000`,
            {
              method: "GET", // Specify the HTTP method (default is GET, but good practice to include it explicitly)
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json", // Include other headers if needed
              },
            }
          );

          const fetchedLis = await response.json();
          console.log("here ", fetchedLis.results);
          if (response.ok) {
            const fetchItemTitles = async () => {
              const results = []; // Temporary array to accumulate results

              // Step 1: Iterate over the results array and extract both itemId and itemType
              for (const item of fetchedLis.results) {
                console.log(item);
                const itemId = item.itemId;
                const type = item.itemType;
                let url;
                if (type === "customize") {
                  url = `${apiUrl}/customize/manage/${itemId}`;
                } else if (type === "expression") {
                  url = `${apiUrl}/expressions/manage/${itemId}`;
                } else if (type === "") {
                } else {
                  url = `${apiUrl}/${type}/manage/${itemId}`;
                }

                try {
                  // Step 2: Use both itemType and itemId in the API URL
                  const response = await fetch(url);

                  if (response.ok) {
                    const resultData = await response.json();
                    resultData.level = item.level;
                    // Step 3: Accumulate the resultData in the results array
                    results.push(resultData);
                  } else {
                    console.error(
                      `Error fetching item with ID ${itemId}:`,
                      response.status
                    );
                  }
                } catch (error) {}
              }

              // Step 4: Once all items are fetched, update the data2 state

              setFilteredData(results);
            };
            fetchItemTitles();
          }
        } catch (error) {}
      };
      fetchData();
    } else if (selectedGender === "Situation") {
      sessionStorage.setItem("bookmarkFilter", "Situation");
      const tagIds = selectedGenders.join(",");
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${apiUrl}/bookmarks/filter?itemType=situation&tagIds=${tagIds}&page=1&limit=1000`,
            {
              method: "GET", // Specify the HTTP method (default is GET, but good practice to include it explicitly)
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json", // Include other headers if needed
              },
            }
          );

          const fetchedLis = await response.json();
          console.log("here ", fetchedLis.results);
          if (response.ok) {
            const fetchItemTitles = async () => {
              const results = []; // Temporary array to accumulate results

              // Step 1: Iterate over the results array and extract both itemId and itemType
              for (const item of fetchedLis.results) {
                console.log(item);
                const itemId = item.itemId;
                const type = item.itemType;
                let url;
                if (type === "customize") {
                  url = `${apiUrl}/customize/manage/${itemId}`;
                } else if (type === "expression") {
                  url = `${apiUrl}/expressions/manage/${itemId}`;
                } else if (type === "") {
                } else {
                  url = `${apiUrl}/${type}/manage/${itemId}`;
                }

                try {
                  // Step 2: Use both itemType and itemId in the API URL
                  const response = await fetch(url);

                  if (response.ok) {
                    const resultData = await response.json();
                    resultData.level = item.level;
                    // Step 3: Accumulate the resultData in the results array
                    results.push(resultData);
                  } else {
                    console.error(
                      `Error fetching item with ID ${itemId}:`,
                      response.status
                    );
                  }
                } catch (error) {}
              }

              // Step 4: Once all items are fetched, update the data2 state

              setFilteredData(results);
            };
            fetchItemTitles();
          }
        } catch (error) {}
      };
      fetchData();
    } else if (selectedGender === "Expression") {
      sessionStorage.setItem("bookmarkFilter", "Expression");
      const psychologyItems = sortedData.filter((item) => item.expressions);
      setFilteredData(psychologyItems);
    } else {
      sessionStorage.removeItem("bookmarkFilter");
      setFilteredData(sortedData);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${apiUrl}/bookmarks?page=1&limit=1000`, {
        method: "GET", // or 'POST' depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Include other headers if needed
        },
      });

      if (response.ok) {
        const result = await response.json();
        if (result.results.length < 1) {
          setIsLoading(false);
        }
        setData(result.results);
      } else {
        // Handle the error as needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    const fetchItemTitles = async () => {
      const results = []; // Temporary array to accumulate results

      // Step 1: Iterate over the results array and extract both itemId and itemType
      for (const item of data) {
        console.log(item);
        const itemId = item.itemId;
        const type = item.itemType;
        let url;
        if (type === "customize") {
          url = `${apiUrl}/customize/manage/${itemId}`;
        } else if (type === "expression") {
          url = `${apiUrl}/expressions/manage/${itemId}`;
        } else if (type === "") {
        } else {
          url = `${apiUrl}/${type}/manage/${itemId}`;
        }

        try {
          // Step 2: Use both itemType and itemId in the API URL
          const response = await fetch(url);

          if (response.ok) {
            const resultData = await response.json();
            resultData.level = item.level;
            // Step 3: Accumulate the resultData in the results array
            results.push(resultData);
          } else {
            console.error(
              `Error fetching item with ID ${itemId}:`,
              response.status
            );
          }
        } catch (error) {}
      }

      // Step 4: Once all items are fetched, update the data2 state

      setData2(results);
      console.log("here");
      setIsLoading(false);
    };

    if (data.length > 0) {
      fetchItemTitles();
    }
  }, [data, apiUrl]); // Dep

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/1`);
        const fetchedData = await response.json();
        setData3(fetchedData); // Store fetched data in state
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/2`);
        const fetchedData = await response.json();
        setData4(fetchedData); // Store fetched data in state
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/3`);
        const fetchedData = await response.json();
        setData5(fetchedData); // Store fetched data in state
      } catch (error) {}
    };

    fetchData();
  }, []);

  const findItemById = (id, node) => {
    if (!node) return null;

    // Check if the current node's id matches the id being searched
    if (node.id === id) return node;

    // Recursively check through children if available
    if (node.children) {
      for (const child of node.children) {
        const result = findItemById(id, child);
        if (result) return result;
      }
    }

    return null;
  };

  const handleGoStudy = (item) => {
    sessionStorage.removeItem("elapsedTime");
    dispatch(setManageID(item.id));
    dispatch(setImageUrl(item.imageurl));
    dispatch(setWhere("bookmark"));
    sessionStorage.setItem("starscoreB", "false");
    sessionStorage.removeItem("starscoreS");
    sessionStorage.setItem("bmf", item.level);
    const fetchData = async () => {
      try {
        if (item.customizes) {
          const languages = ["kor", "eng"];
          const difficulties = ["easyId", "normalId", "difficultId"];
          const fetchedLis = {};

          for (const language of languages) {
            fetchedLis[language] = {};
            const languageItem = item.customizes.find(
              (cust) => cust.language === language
            );

            if (languageItem) {
              for (const difficulty of difficulties) {
                const id = languageItem[difficulty];

                if (id) {
                  const response = await fetch(
                    `${apiUrl}/customize_script/${id}`
                  );
                  const data = await response.json();

                  if (response.ok) {
                    console.log(
                      `Fetched data for ${language} - ${difficulty}:`,
                      data
                    );
                    fetchedLis[language][difficulty] = data;
                  }
                }
              }
            }
          }

          const countUniqueTypes = (data) => {
            const uniqueTypes = new Set(
              data.map((item) => item.type).filter((type) => type !== "")
            );
            return uniqueTypes.size;
          };

          const fetchQuizData = async () => {
            try {
              const response = await fetch(
                `${apiUrl}/customize_quiz?scriptId=${fetchedLis[language][difficulty].id}`
              );
              const data = await response.json();
              return countUniqueTypes(data);
            } catch (err) {
              console.error("Fetch error:", err);
            }
          };

          const pgs = (await fetchQuizData()) + 3;

          dispatch(setType("mbti"));
          navigate("/studyonboard", {
            state: { script: fetchedLis, item: item, pgs: pgs },
          });
        }
        if (item.situations) {
          const languages = ["kor", "eng"];
          const difficulties = ["easyId", "normalId", "difficultId"];
          const fetchedLis = {};

          for (const language of languages) {
            fetchedLis[language] = {};
            const languageItem = item.situations.find(
              (cust) => cust.language === language
            );

            if (languageItem) {
              for (const difficulty of difficulties) {
                const id = languageItem[difficulty];

                if (id) {
                  const response = await fetch(
                    `${apiUrl}/situation_script/${id}`
                  );
                  const data = await response.json();

                  if (response.ok) {
                    console.log(
                      `Fetched data for ${language} - ${difficulty}:`,
                      data
                    );
                    fetchedLis[language][difficulty] = data;
                  }
                }
              }
            }
          }

          const countUniqueTypes = (data) => {
            const uniqueTypes = new Set(
              data.map((item) => item.type).filter((type) => type !== "")
            );
            return uniqueTypes.size;
          };

          const fetchQuizData = async () => {
            try {
              const response = await fetch(
                `${apiUrl}/situation_quiz?scriptId=${fetchedLis[language][difficulty].id}`
              );
              const data = await response.json();
              return countUniqueTypes(data);
            } catch (err) {
              console.error("Fetch error:", err);
            }
          };

          const pgs = (await fetchQuizData()) + 3;

          dispatch(setType("situation"));
          navigate("/studyonboard", {
            state: { script: fetchedLis, item: item, pgs: pgs },
          });
        }

        if (item.psychologys) {
          const languages = ["kor", "eng"];
          const difficulties = ["easyId", "normalId", "difficultId"];
          const fetchedLis = {};

          for (const language of languages) {
            fetchedLis[language] = {};
            const languageItem = item.psychologys.find(
              (cust) => cust.language === language
            );

            if (languageItem) {
              for (const difficulty of difficulties) {
                const id = languageItem[difficulty];

                if (id) {
                  const response = await fetch(
                    `${apiUrl}/psychology_script/${id}`
                  );
                  const data = await response.json();

                  if (response.ok) {
                    console.log(
                      `Fetched data for ${language} - ${difficulty}:`,
                      data
                    );
                    fetchedLis[language][difficulty] = data;
                  }
                }
              }
            }
          }
          const countUniqueTypes = (data) => {
            const uniqueTypes = new Set(
              data.map((item) => item.type).filter((type) => type !== "")
            );
            return uniqueTypes.size;
          };

          const fetchQuizData = async () => {
            try {
              const response = await fetch(
                `${apiUrl}/psychology_quiz?scriptId=${fetchedLis[language][difficulty].id}`
              );
              const data = await response.json();
              return countUniqueTypes(data);
            } catch (err) {
              console.error("Fetch error:", err);
            }
          };

          const pgs = (await fetchQuizData()) + 3;

          dispatch(setType("psychology"));
          navigate("/studyonboard", {
            state: { script: fetchedLis, item: item, pgs: pgs },
          });
        } else if (item.expressions) {
          sessionStorage.setItem("asbo", "true");
          navigate(`/vocablist/${item.id}`);
        }
      } catch (error) {}
    };

    fetchData();
  };

  const sortData = useCallback(
    (dataToSort) => {
      const sortedData = [...dataToSort];
      if (sortConfig.key === "latest") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? new Date(b.createdAt) - new Date(a.createdAt)
            : new Date(a.createdAt) - new Date(b.createdAt);
        });
      } else if (sortConfig.key === "alphabetical") {
        sortedData.sort((a, b) => {
          const aTitle = a.customizes
            ? a.customizes.find((customize) => customize.language === "eng")
                ?.title
            : a.situations
            ? a.situations.find((customize) => customize.language === "eng")
                ?.title
            : a.psychologys
            ? a.psychologys.find((customize) => customize.language === "eng")
                ?.title
            : a.title || "";
          const bTitle = b.customizes
            ? b.customizes.find((customize) => customize.language === "eng")
                ?.title
            : b.situations
            ? b.situations.find((customize) => customize.language === "eng")
                ?.title
            : b.psychologys
            ? b.psychologys.find((customize) => customize.language === "eng")
                ?.title
            : b.title || "";
          return sortConfig.direction === "ascending"
            ? bTitle.localeCompare(aTitle)
            : aTitle.localeCompare(bTitle);
        });
      } else if (sortConfig.key === "rating") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? parseInt(a.averageRating ?? -1, 10) -
                parseInt(b.averageRating ?? -1, 10)
            : parseInt(b.averageRating ?? -1, 10) -
                parseInt(a.averageRating ?? -1, 10);
        });
      }

      return sortedData;
    },
    [sortConfig]
  );

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  useEffect(() => {
    console.log(data2);
    const sortedData = sortData(data2);

    setSortedData(sortedData);
    setFilteredData(sortedData);
  }, [data2, sortConfig, sortData]);

  const [selectKorEng, setSelectKorEng] = useState({});

  const formatEnglishCategory = (category) => {
    return category
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getCategoryName = (category) => {
    if (selectKorEng.expression) {
      const entries = Object.entries(selectKorEng.expression);
      for (const [kor, eng] of entries) {
        if (eng === category) {
          return language === "kor" ? kor : formatEnglishCategory(eng);
        }
      }
    }
    return language === "kor" ? category : formatEnglishCategory(category);
  };

  const [expandedIndex, setExpandedIndex] = useState(null); // To track expanded accordion
  const [expandedIndex2, setExpandedIndex2] = useState(null);
  const [selectedGenders, setSelectedGenders] = useState([]); // To track selected items
  const [topics, setTopics] = useState([]); // Replace with actual data
  const [topics2, setTopics2] = useState([]); // Replace with actual data
  const [topics3, setTopics3] = useState([]); // Replace with actual data

  useEffect(() => {
    if (data3 && data3.children) {
      const topics = data3.children.flatMap(
        (firstLevelChild, firstLevelIndex) => {
          const firstLevelNames = firstLevelChild.translations
            ? firstLevelChild.translations
                .filter(
                  (translation) =>
                    translation.language === language.toLowerCase()
                )
                .map((translation) => translation.name)
            : [];

          const secondLevel = firstLevelChild.children || [];
          const secondLevelData = secondLevel.flatMap(
            (secondLevelChild, secondLevelIndex) => {
              const secondLevelNames = secondLevelChild.translations
                ? secondLevelChild.translations
                    .filter(
                      (translation) =>
                        translation.language === language.toLowerCase()
                    )
                    .map((translation) => translation.name)
                : [];

              const reversedSecondLevelNames = [...secondLevelNames].reverse();

              const thirdLevel = secondLevelChild.children || [];
              const thirdLevelData = thirdLevel.flatMap((thirdLevelChild) =>
                thirdLevelChild.translations
                  ? thirdLevelChild.translations
                      .filter(
                        (translation) =>
                          translation.language === language.toLowerCase()
                      )
                      .map((translation) => ({
                        name: translation.name,
                        id: thirdLevelChild.id,
                      }))
                  : []
              );

              const reversedThirdLevelNames = [...thirdLevelData].reverse();

              return reversedSecondLevelNames.map((name, index) => ({
                id: `second-${firstLevelIndex}-${secondLevelIndex}-${index}`,
                title: name,
                thirdLevelData: reversedThirdLevelNames, // Include third-level data
              }));
            }
          );

          const reversedSecondLevelData = [...secondLevelData].reverse();

          return firstLevelNames.map((firstLevelName, index) => ({
            id: `first-${firstLevelIndex}-${index}`,
            title: firstLevelName,
            content: (
              <div key={`first-content-${index}`}>
                <div className="flex flex-wrap gap-2">
                  {reversedSecondLevelData.map((secondLevelTopic) => {
                    // Check if all thirdLevelData items are selected
                    const allSelected = secondLevelTopic.thirdLevelData.every(
                      (thirdName) => selectedGenders.includes(thirdName.id)
                    );
                    return (
                      <div key={secondLevelTopic.id}>
                        <div className="font-bold text-[#000]  dark:text-[#fff] mb-2">
                          {secondLevelTopic.title}
                        </div>
                        <div className="flex flex-wrap gap-2">
                          <div
                            className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                              allSelected
                                ? darkMode
                                  ? "bg-[#fff] border-[#fff] text-black" // Ensure text is white in dark mode
                                  : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                                : darkMode
                                ? "border-[#e9ecef] text-white" // Apply white text for unselected items in dark mode
                                : "border-[#e9ecef] text-[#000]"
                            }`}
                            style={{ flexBasis: "calc(33.333% - 6px)" }}
                            onClick={() => {
                              if (allSelected) {
                                // Deselect all third-level items
                                const idsToRemove =
                                  secondLevelTopic.thirdLevelData.map(
                                    (thirdName) => thirdName.id
                                  );
                                const keysToRemove =
                                  secondLevelTopic.thirdLevelData.map(
                                    (thirdName) => thirdName.name
                                  );

                                setSelectedGenders((prevSelectedGenders) =>
                                  prevSelectedGenders.filter(
                                    (id) => !idsToRemove.includes(id)
                                  )
                                );
                                setSelectedKeys((prevSelectedKeys) =>
                                  prevSelectedKeys.filter(
                                    (key) => !keysToRemove.includes(key)
                                  )
                                );
                              } else {
                                // Select all third-level items, and mimic manual selection
                                secondLevelTopic.thirdLevelData.forEach(
                                  (thirdName) => {
                                    if (
                                      !selectedGenders.includes(thirdName.id)
                                    ) {
                                      handleSelect(thirdName.id); // Select the thirdName ID
                                    }
                                    if (
                                      !selectedKeys.includes(thirdName.name)
                                    ) {
                                      handleSelect2(secondLevelTopic.title); // Pass the secondLevelTopic title
                                    }
                                  }
                                );
                              }
                            }}
                          >
                            <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                              {translations.ALL}
                            </div>
                          </div>
                          {secondLevelTopic.thirdLevelData.map((thirdName) => (
                            <div
                              key={thirdName.id}
                              onClick={() => {
                                handleSelect(thirdName.id);
                                handleSelect2(secondLevelTopic.title);
                              }}
                              className={`flex items-center justify-center h-10 w-28 px-[10px] border border-solid rounded-full ${
                                selectedGenders.includes(thirdName.id)
                                  ? darkMode
                                    ? "bg-white border-white text-black"
                                    : "bg-blue-100 border-blue-500 text-blue-500"
                                  : darkMode
                                  ? "border-gray-300 text-white"
                                  : "border-gray-300 text-black"
                              } overflow-hidden whitespace-nowrap text-ellipsis`}
                              style={{ flexBasis: "calc(33.333% - 6px)" }}
                            >
                              <div className="font-[14px] font-medium leading-tight truncate">
                                {thirdName.name}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ),
          }));
        }
      );

      setTopics(topics.reverse());
    }
  }, [data3, selectedGenders, language]);
  // Update topics and filteredTopics based on `data` and `selectedGenders`
  useEffect(() => {
    if (data4 && data4.children) {
      const topics = data4.children.flatMap(
        (firstLevelChild, firstLevelIndex) => {
          const firstLevelNames = firstLevelChild.translations
            ? firstLevelChild.translations
                .filter((translation) => translation.language === language)
                .map((translation) => translation.name)
            : [];

          const secondLevel = firstLevelChild.children || [];
          const secondLevelData = secondLevel.map(
            (secondLevelChild, secondLevelIndex) => {
              const secondLevelNames = secondLevelChild.translations
                ? secondLevelChild.translations
                    .filter((translation) => translation.language === language)
                    .map((translation) => translation.name)
                : [];

              return {
                id: `second-${firstLevelIndex}-${secondLevelIndex}`,
                title: secondLevelNames[0],
                content: (
                  <div
                    key={`second-content-${secondLevelIndex}`}
                    className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                      selectedGenders.includes(secondLevelChild.id)
                        ? darkMode
                          ? "bg-[#fff] border-[#fff] text-[#000]"
                          : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                        : darkMode
                        ? " border-[#e9ecef] text-[#fff]"
                        : "border-[#e9ecef] text-[#000]"
                    }`}
                    onClick={() => {
                      handleSelect(secondLevelChild.id);
                      handleSelect2(secondLevelNames[0]);
                    }}
                    style={{ flexBasis: "calc(33.333% - 6px)" }}
                  >
                    <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                      {secondLevelNames[0]}
                    </div>
                  </div>
                ),
              };
            }
          );

          return firstLevelNames.map((firstLevelName, index) => ({
            id: `first-${firstLevelIndex}-${index}`,
            title: firstLevelName,
            content: (
              <div
                key={`first-content-${index}`}
                className="flex flex-wrap gap-2"
              >
                {/* 전체 Button alongside other second-level topics */}
                <div
                  className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] cursor-pointer ${
                    secondLevel.every((secondLevelChild) =>
                      selectedGenders.includes(secondLevelChild.id)
                    )
                      ? darkMode
                        ? "bg-[#fff] border-[#fff] text-[#000]"
                        : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                      : darkMode
                      ? "border-[#e9ecef] text-[#fff]"
                      : "border-[#e9ecef] text-[#000]"
                  }`}
                  onClick={() => {
                    const idsToSelect = secondLevel.map(
                      (secondLevelChild) => secondLevelChild.id
                    );
                    const titlesToSelect = secondLevel.map(
                      (secondLevelChild) =>
                        secondLevelChild.translations.find(
                          (translation) => translation.language === language
                        )?.name
                    );

                    if (
                      idsToSelect.every((id) => selectedGenders.includes(id)) &&
                      titlesToSelect.every((title) =>
                        selectedKeys.includes(title)
                      )
                    ) {
                      // If all are already selected, deselect all
                      secondLevel.forEach((secondLevelChild) => {
                        const title = secondLevelChild.translations.find(
                          (translation) => translation.language === language
                        )?.name;

                        handleSelect(secondLevelChild.id); // Deselect each second-level ID
                        if (title) {
                          handleSelect2(title); // Handle deselection for each title
                        }
                      });
                    } else {
                      // Otherwise, select all
                      secondLevel.forEach((secondLevelChild) => {
                        const title = secondLevelChild.translations.find(
                          (translation) => translation.language === language
                        )?.name;

                        handleSelect(secondLevelChild.id); // Select each second-level ID
                        if (title) {
                          handleSelect2(title); // Handle selection for each title
                        }
                      });
                    }
                  }}
                  style={{ flexBasis: "calc(33.333% - 56px)" }}
                >
                  <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                    {translations.ALL}
                  </div>
                </div>

                {/* Render each second-level button */}
                {secondLevelData.map((secondLevelTopic) => (
                  <div key={secondLevelTopic.id}>
                    {secondLevelTopic.content}
                  </div>
                ))}
              </div>
            ),
          }));
        }
      );

      setTopics2(topics.reverse());
    }
  }, [data4, selectedGenders, language]); // Update when `data` or `selectedGenders` changes
  useEffect(() => {
    if (data5 && data5.children) {
      const topics = data5.children.flatMap((child) => {
        const names = child.translations
          ? child.translations
              .filter((translation) => translation.language === language)
              .map((translation) => ({
                id: child.id,
                name: translation.name,
              }))
          : [];
        return names;
      });

      setTopics3(topics);
    }
  }, [data5, selectedGenders, language]);
  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    console.log(selectedGender);
    setSelectedGenders([]);
  }, [selectedGender]);
  useEffect(() => {
    setSelectedGenders([]);
    setSelectedKeys([]);
  }, [expandedIndex2]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative min-x-[360px] w-full bg-[#faf8f6] dark:bg-[#121418]">
        {/* Overlay */}
        <div
          ref={overlayRef}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "80%",
            zIndex: 100,
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        ></div>

        {/* Updated Item List Section */}
        <div className="relative left-0 top-0 w-full flex flex-col items-start justify-start px-[16px]">
          <div className="relative self-stretch h-[50px] shrink-0">
            <div className="absolute left-0 top-[7px] w-full flex flex-row items-center justify-between">
              <div
                className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-start"
                onClick={handleGoBack}
              >
                {/* <img width="8" height="16" src="back.png"></img> */}
                <IoIosArrowBack
                  color={darkMode ? "white" : "black"}
                  size={30}
                />
              </div>
              <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] text-center whitespace-nowrap">
                {translations.BOOKMARK}
              </div>
              <div className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-center">
                <div className="relative w-[30px] h-[30px] shrink-0">
                  <div
                    className="absolute left-0 right-0 top-0 bottom-0"
                    onClick={toggleOverlay}
                  >
                    <CgSortAz
                      color={darkMode ? "white" : "black"}
                      size={30}
                    ></CgSortAz>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[30px]"></div>
        </div>
        <div
          className={`w-full h-[44px] flex items-center justify-between px-4 ${
            darkMode
              ? "bg-[#22232a]"
              : "bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)]"
          }`}
        >
          <div className="flex items-center gap-[8px]">
            <div className="flex items-center justify-center py-[5px] px-[8px] bg-[#fff] dark:bg-[#31333e] rounded-full">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#3677f6] dark:text-[#a9b6ca] whitespace-nowrap">
                필터
              </div>
            </div>
            <div className="flex items-center gap-[4px]">
              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
                {selectedGender}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-center gap-[4px]">
          {/* Sorting Options */}
          <div className="w-[95%] mx-auto">
            <div className="flex items-center justify-end gap-[5px] mb-[10px] mt-[10px]">
              <div
                className={`text-[10px] leading-[100%] font-sans font-medium ${
                  sortConfig.key === "latest"
                    ? "text-[#3677f6]"
                    : "text-[#666b81]"
                } whitespace-nowrap cursor-pointer`}
                onClick={() => handleSort("latest")}
              >
                {translations.ORDER_DATE}{" "}
                {sortConfig.key === "latest" &&
                  (sortConfig.direction === "ascending" ? "↓" : "↑")}
              </div>
              <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
              <div
                className={`text-[10px] leading-[100%] font-sans font-medium ${
                  sortConfig.key === "alphabetical"
                    ? "text-[#3677f6]"
                    : "text-[#666b81]"
                } whitespace-nowrap cursor-pointer`}
                onClick={() => handleSort("alphabetical")}
              >
                {translations.ORDER_AL}{" "}
                {sortConfig.key === "alphabetical" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </div>
              <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
              <div
                className={`text-[10px] leading-[100%] font-sans font-medium ${
                  sortConfig.key === "rating"
                    ? "text-[#3677f6]"
                    : "text-[#666b81]"
                } whitespace-nowrap cursor-pointer`}
                onClick={() => handleSort("rating")}
              >
                {translations.ORDER_RA}{" "}
                {sortConfig.key === "rating" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="w-full flex flex-col items-start bg-[#fff] dark:bg-[#31333e] overflow-y-auto">
            {/* 카드 */}
            {isLoading ? (
              <div className="flex justify-center bg-[#faf8f6] dark:bg-[#121418] items-center w-full h-full">
                <BeatLoader
                  color="#3677f6"
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : !isLoading && data2.length < 1 ? (
              <div className="flex flex-col justify-center bg-[#faf8f6] dark:bg-[#121418] items-center h-full w-full text-center p-4">
                <div className="text-gray-500 text-lg mb-2">
                  {translations.list2}
                </div>
                <FaTimes className="text-gray-500 text-3xl" />
              </div>
            ) : (
              filteredData.slice(0, visibleItems).map((item, index) => {
                // Check if `customizes` is defined and has at least 2 elements
                if (
                  (item.customizes && item.customizes.length > 1) ||
                  item.situations ||
                  item.psychologys
                ) {
                  return (
                    <div
                      key={index}
                      className="relative w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] border-b-0"
                      onClick={() => handleGoStudy(item)}
                    >
                      {/* Titles */}
                      <div className="flex flex-col gap-[17px]">
                        <div className="flex flex-col gap-[12px]">
                          <div className="flex items-center">
                            {/* English title */}
                            <div className="text-[14px] leading-[110%] font-sans font-bold text-[#333] dark:text-[#fff] whitespace-nowrap overflow-hidden text-ellipsis max-w-[80vw]">
                              {item.customizes
                                ? item.customizes.find(
                                    (customize) =>
                                      customize.language === language
                                  )?.title
                                : item.situations
                                ? item.situations.find(
                                    (customize) =>
                                      customize.language === language
                                  )?.title
                                : item.psychologys.find(
                                    (customize) =>
                                      customize.language === language
                                  )?.title}{" "}
                              {/* English title */}
                            </div>
                          </div>
                          {/* Korean title */}
                          <div className="text-[14px] leading-[110%] font-sans font-medium text-[#333] dark:text-[#fff] whitespace-nowrap overflow-hidden text-ellipsis max-w-[80vw]">
                            {item.customizes
                              ? item.customizes.find(
                                  (customize) =>
                                    customize.language === oppositeLanguage
                                )?.title
                              : item.situations
                              ? item.situations.find(
                                  (customize) =>
                                    customize.language === oppositeLanguage
                                )?.title
                              : item.psychologys.find(
                                  (customize) =>
                                    customize.language === oppositeLanguage
                                )?.title}{" "}
                            {/* Korean title */}
                          </div>
                        </div>

                        <div className="absolute bottom-[14px] right-[16px] flex space-x-2">
                          {/* First Tag */}
                          <span
                            className={`px-2 py-1 rounded text-[10px] font-semibold ${
                              item.level
                                ? item.level === "difficult"
                                  ? "bg-[#E0E0E0] text-[#333333]"
                                  : item.level === "normal"
                                  ? "bg-[#D5F5E3] text-[#007BFF]"
                                  : "bg-[#D6EAF8] text-[#3333FF]"
                                : ""
                            }`}
                          >
                            {item.level
                              ? item.level === "difficult"
                                ? "Difficult"
                                : item.level === "normal"
                                ? "Normal"
                                : "Easy"
                              : ""}
                          </span>

                          {/* Second Tag */}
                          <span
                            className={`px-2 py-1 rounded text-[10px] font-semibold ${
                              item.customizes
                                ? "bg-[#E0E0E0] text-[#333333]"
                                : item.situations
                                ? "bg-[#D5F5E3] text-[#007BFF]"
                                : "bg-[#D6EAF8] text-[#3333FF]"
                            }`}
                          >
                            {item.customizes
                              ? "Customize"
                              : item.situations
                              ? "Situation"
                              : "Psychology"}
                          </span>
                        </div>

                        {/* Tags */}
                        <div className="flex items-center gap-[3px]">
                          {item.tag &&
                            item.tag.map((tag, tagIndex) => (
                              <div
                                key={tagIndex}
                                className="flex items-center gap-[3px]"
                              >
                                <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                                  {item.customizes
                                    ? findItemById(
                                        tag.id,
                                        data3
                                      )?.translations.find(
                                        (t) => t.language === language
                                      )?.name
                                    : item.situations
                                    ? findItemById(
                                        tag.id,
                                        data4
                                      )?.translations.find(
                                        (t) => t.language === language
                                      )?.name
                                    : findItemById(
                                        tag.id,
                                        data5
                                      )?.translations.find(
                                        (t) => t.language === language
                                      )?.name || "Loading..."}
                                  {/* Display the tag id */}
                                </div>
                                {tagIndex < item.tag.length - 1 && (
                                  <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>

                      {/* Rating */}
                      <div className="flex items-center gap-[4px]">
                        <img
                          className={darkMode ? "filter: invert" : ""}
                          width="15"
                          height="15"
                          src={`star.png`}
                          alt="rating star"
                        />
                        <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] whitespace-nowrap">
                          {item.averageRating.toFixed(1)}{" "}
                          {/* Display the rating */}
                        </div>
                      </div>
                    </div>
                  );
                }
                if (item.expressions && item.expressions.length > 1) {
                  return (
                    <div
                      key={index}
                      className="relative w-full flex flex-col py-[14px] px-[16px] bg-[#fff] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] border-b-0"
                      onClick={() => handleGoStudy(item)}
                    >
                      {/* Top Section: Titles */}
                      <div className="flex flex-col gap-[12px]">
                        {/* English Title */}
                        <div className="text-[14px] leading-[110%] font-sans font-bold text-[#333] dark:text-[#fff] whitespace-nowrap overflow-hidden text-ellipsis max-w-[70%]">
                          {
                            item.expressions.find(
                              (customize) => customize.language === "kor"
                            )?.expressive_language
                          }
                        </div>

                        {/* Korean Title */}
                        <div className="text-[14px] leading-[110%] font-sans font-medium text-[#333] dark:text-[#fff] whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                          {
                            item.expressions.find(
                              (customize) => customize.language === "eng"
                            )?.expressive_language
                          }
                        </div>
                      </div>

                      {/* Manage Type Tag */}
                      <span
                        className={`absolute bottom-[14px] right-[16px] px-2 py-1 rounded text-[10px] font-semibold ${
                          item.manageType === "customize"
                            ? "bg-[#E0E0E0] text-[#333333]"
                            : item.manageType === "situation"
                            ? "bg-[#D5F5E3] text-[#007BFF]"
                            : "bg-[#E8F5E9] text-[#1B5E20]"
                        }`}
                      >
                        {"Voca"}
                      </span>
                    </div>
                  );
                }
              })
            )}

            {/* Load More Button */}
            {visibleItems < data2.length && (
              <div
                className="flex justify-center text-center py-[14px] bg-[#fff] dark:bg-[#31333e]
 border border-[#e9ecef] dark:border-[#000] w-[100%]"
              >
                <button
                  onClick={loadMoreItems}
                  className="px-4 py-2 bg-[#007bff] text-white rounded"
                >
                  더보기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Overlay */}
      <div
        ref={overlayRef}
        className={`fixed bottom-0 left-0 w-full bg-white dark:bg-[#22232a]
 transition-transform duration-300 ease-in-out overflow-y-auto ${
   isOverlayVisible ? "transform translate-y-0" : "transform translate-y-full"
 }`}
        style={{
          height: "80%",
          zIndex: 100,
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
        }}
      >
        {/* Title of the overlay */}
        <div className="p-4">
          <h2 className="text-lg font-bold dark:text-white">
            {translations.filter1}
          </h2>
        </div>
        {/* Content of the overlay */}
        <div className="">
          <h3 className="text-sm font-black text-gray-600 mb-4 bg-[#E2E8EF] px-4 py-2">
            1차 필터
          </h3>
          <div className="flex flex-wrap gap-[6px] justify-center">
            {typeList.map((gender) => (
              <div
                key={gender}
                className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                  selectedGender === gender
                    ? darkMode
                      ? "bg-white border-white text-black"
                      : "bg-blue-100 border-blue-500 text-blue-500"
                    : darkMode
                    ? "border-gray-300 text-white"
                    : "border-gray-300 text-black"
                }`}
                onClick={() => handleSelect3("gender", gender)}
                style={{ flexBasis: "calc(33.333% - 6px)" }}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {getCategoryName(gender)}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Content2 of the overlay */}
        <div className="mt-8">
          <h3 className="text-sm font-black text-gray-600 bg-[#E2E8EF] px-4 py-2">
            2차 필터
          </h3>
          {selectedGender === "MBTI" || selectedGender === "Persona" ? (
            <div className="flex flex-col">
              {!selectedGender ? null : topics.length === 0 ? (
                <div className="flex justify-center items-center">
                  <BeatLoader
                    color="#3677f6"
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                topics.map((topic, index) => (
                  <div
                    key={index}
                    className="w-full border-b border-[#e9ecef] dark:border-[#666b81] bg-[#fff] dark:bg-[#22232a]"
                  >
                    <button
                      onClick={() => handleToggle(index)}
                      className={`flex justify-between items-center w-full p-4 border-b border-[#e9ecef] dark:border-[#666b81] last:border-none ${
                        expandedIndex !== index &&
                        selectedGenders.length > 0 &&
                        expandedIndex2 !== index
                          ? darkMode
                            ? "text-[#555]"
                            : "text-[#ccc]"
                          : darkMode
                          ? "text-[#fff]"
                          : "text-[#000]"
                      }`}
                    >
                      <span className="text-[16px] font-sans font-bold ">
                        {topic.title}
                      </span>
                      <img
                        width="30"
                        height="30"
                        src={`open.png`}
                        alt="Toggle"
                        className={`transition-transform duration-300 ${
                          expandedIndex === index ? "rotate-180" : "rotate-0"
                        } ${darkMode ? "filter invert" : ""}`}
                      />
                    </button>
                    {expandedIndex === index && (
                      <div className="p-4 bg-[#fff] dark:bg-[#22232a]">
                        {/* 메모 */}
                        <div className="text-[14px] font-sans">
                          {topic.content}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          ) : selectedGender === "Situation" ? (
            <div className="flex flex-col">
              {!selectedGender ? null : topics2.length === 0 ? (
                <div className="flex justify-center items-center">
                  <BeatLoader
                    color="#3677f6"
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                topics2.map((topic, index) => (
                  <div
                    key={index}
                    className="w-full border-b border-[#e9ecef] dark:border-[#666b81] bg-[#fff] dark:bg-[#22232a]"
                  >
                    <button
                      onClick={() => handleToggle(index)}
                      className={`flex justify-between items-center w-full p-4 border-b border-[#e9ecef] dark:border-[#666b81] last:border-none ${
                        expandedIndex !== index &&
                        selectedGenders.length > 0 &&
                        expandedIndex2 !== index
                          ? darkMode
                            ? "text-[#555]"
                            : "text-[#ccc]"
                          : darkMode
                          ? "text-[#fff]"
                          : "text-[#000]"
                      }`}
                    >
                      <span className="text-[16px] font-sans font-bold ">
                        {topic.title}
                      </span>
                      <img
                        width="30"
                        height="30"
                        src={`open.png`}
                        alt="Toggle"
                        className={`transition-transform duration-300 ${
                          expandedIndex === index ? "rotate-180" : "rotate-0"
                        }${darkMode ? "filter invert" : ""}`}
                      />
                    </button>
                    {expandedIndex === index && (
                      <div className="p-4 bg-[#fff] dark:bg-[#22232a]">
                        <div className="text-[14px] font-sans text-[#666b81]">
                          {topic.content}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          ) : selectedGender === "Psychology" ? (
            <div className="flex flex-col">
              {!selectedGender ? null : topics3.length === 0 ? (
                <div className="flex justify-center items-center">
                  <BeatLoader
                    color="#3677f6"
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                topics3.map((topic) => (
                  <div
                    key={topic.id}
                    className={`self-stretch w-[80%] mx-auto h-[54px] flex items-center justify-center mt-4 mb-2 py-[10px] px-[20px] shadow-lg rounded-[12px] cursor-pointer ${
                      selectedGenders.includes(topic.id)
                        ? darkMode
                          ? "bg-white border-white text-black"
                          : "bg-blue-100 border-blue-500 text-blue-500"
                        : darkMode
                        ? "border-gray-300 text-white"
                        : "border-gray-300 text-black"
                    }`}
                    onClick={() => {
                      handleSelect(topic.id);
                    }}
                  >
                    <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                      {topic.name}
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : selectedGender === "Expression" ? (
            <div className="flex flex-col justify-center bg-[#fff] dark:bg-[#121418] items-center h-full w-full text-center p-4">
              <FaTimes className="text-gray-500 text-3xl mb-2" />
              <div className="text-gray-500 text-lg">
                단어는 2차필터가 없습니다
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center bg-[#fff] dark:bg-[#121418] items-center h-full w-full text-center p-4">
              <div className="text-gray-500 text-lg">
                1차 필터를 선택해주세요
              </div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="mt-8 w-full">
          <div className="flex-none pb-[20px] px-[16px]">
            <button
              className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
              onClick={handleComplete}
            >
              {translations.filter2}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookMark;
