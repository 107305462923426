import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { jwtDecode as jwt_decode } from "jwt-decode";
import { IoIosArrowBack } from "react-icons/io";

const Purchase = () => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.userId);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userId, setUserId] = useState("");
  const userInfo = useSelector((state) => state.userInfo);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${apiUrl}/products?limit=10&page=1`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Filter to include only products where isDisplayed is true
        const displayedProducts = data.products.filter(
          (product) => product.isDisplayed
        );
        setProducts(displayedProducts);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Fetch error:", error); // Optional: Log the error for debugging
      });
  }, []); // Empty array ensures this runs only once when the component mounts

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  function decodeToken(token) {
    try {
      const decoded = jwt_decode(token);

      setUserId(decoded.userid);

      return decoded;
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    decodeToken(accessToken);
  }, []);
  useEffect(() => {
    // Load the NicePay script when the component mounts
    const script = document.createElement("script");
    script.src = "https://pay.nicepay.co.kr/v1/js/";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  const serverAuth = async (amount, name) => {
    // Ensure the script is loaded and available
    const response = await fetch(`${apiUrl}/payment`, {
      method: "GET", // Use DELETE method
      headers: {
        Authorization: `Bearer ${accessToken}`, // Include the access token
        "Content-Type": "application/json",
      },
      // You can include a body if required by the API, but often DELETE requests don't have a body
      // body: JSON.stringify({ someData: "value" }),
    });

    if (response.ok) {
      const result = await response.json();

      if (window.AUTHNICE) {
        window.AUTHNICE.requestPay({
          clientId: result.clientId,
          method: "card",
          orderId: result.orderId,
          amount: amount, //regular
          goodsName: name, //name
          returnUrl: "https://pastellapp.com/api/payment/serverAuth", //API를 호출할 Endpoint 입력
          mallReserved: JSON.stringify({ userId: userInfo.userid }),
          fnError: function (result) {
            alert("개발자확인용 : " + result.errorMsg);
          },
        });
      } else {
      }
    } else {
      // Handle error response
    }
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#faf8f6] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6] dark:bg-[#121418]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center"
          onClick={handleGoBack}
        >
          <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
          이용권 구매
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      <div className="absolute left-[16px] top-[112px] w-[91%] flex flex-col items-start justify-center gap-[24px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[18px]">
          <div className="text-[16px] leading-[100%] font-['Pretendard'] font-bold text-[#000] dark:text-[#fff] whitespace-nowrap">
            파스텔라 맞춤 상품
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            {products.map((product) => (
              <div
                key={product.id}
                className="self-stretch flex flex-col items-start justify-start p-[16px] bg-[#fff] rounded-[12px] shadow-lg"
                onClick={() => {
                  serverAuth(product.salePrice, product.name);
                }}
              >
                {/* Product name and content in one div */}
                <div className="flex flex-col items-start justify-start gap-[10px]">
                  <div
                    className="text-[14px] leading-[100%] font-['Pretendard'] font-bold whitespace-nowrap"
                    style={
                      product.name === "PREMIUM 플랜"
                        ? {
                            background:
                              "linear-gradient(64.95deg, #61e3eb 0%, #3677f6 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }
                        : {}
                    }
                  >
                    {product.name}
                  </div>
                  <div className="text-[12px] leading-[100%] font-['Pretendard'] font-medium text-[#000] text-center whitespace-nowrap">
                    {product.content}
                  </div>
                </div>

                {/* Right-aligned price on the third row */}
                <div className="self-end text-[12px] leading-[100%] font-['Pretendard'] font-medium text-[#000] text-right mt-[8px] whitespace-nowrap">
                  {product.regularPrice !== product.salePrice ? (
                    <div>
                      {/* Display both salePrice and crossed out regularPrice */}
                      <span
                        style={{
                          textDecoration: "line-through",
                          color: "gray",
                          marginRight: "8px",
                        }}
                      >
                        KRW {product.regularPrice}
                      </span>
                      <span>KRW {product.salePrice}</span>
                    </div>
                  ) : (
                    // If the prices are the same, just show salePrice
                    <span>KRW {product.salePrice}</span>
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* Refund Policy Link */}
          <div
            className="text-[14px] leading-[100%] font-['Pretendard'] font-medium text-[#000] dark:text-[#fff] underline cursor-pointer mt-6"
            onClick={() => navigate("/refundpolicy")}
          >
            환불 정책
          </div>
        </div>
      </div>

      {/* Footer */}

      {/* Toast Container */}
    </div>
  );
};

export default Purchase;
