import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";

const CircleComponent = ({
  title = "Default Title",
  sex = "MALE",
  age = "20S",
  job = "WORKER",
  love = "MARRIED",
  mbti = "MBTI",
  startIndex = 15,
  count = 10,
  showText, // Use showText to control visibility
  onClick,
}) => {
  const navigate = useNavigate();
  const displayLove = love === "IN_A_RELATIONSHIP" ? "LOVE" : love;

  // State to manage delayed visibility effect
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [fadeOpacity, setFadeOpacity] = useState(false);

  useEffect(() => {
    if (showText) {
      // First show the elements after a delay
      const timer = setTimeout(() => setIsTextVisible(true), 500);
      // Then start the opacity transition
      const opacityTimer = setTimeout(() => setFadeOpacity(true), 700);
      return () => {
        clearTimeout(timer);
        clearTimeout(opacityTimer);
      };
    } else {
      setIsTextVisible(false);
      setFadeOpacity(false);
    }
  }, [showText]);

  return (
    <div className="relative flex items-center justify-center w-96 h-96 mx-auto my-5">
      {/* Larger, Transparent Circle with Inward Shadow */}
      <div
        className="flex flex-col items-center justify-center w-60 h-60 rounded-full border-4 border-white bg-transparent text-center z-40"
        onClick={onClick}
        style={{
          boxShadow:
            "inset 0 0 30px rgba(255, 255, 255, 0.4), inset 0 0 60px rgba(255, 255, 255, 0.2)",
        }}
      >
        {isTextVisible ? (
          <>
            {/* Dynamic Title Text with Fade-In Effect */}
            <h1
              className={`text-[20px] text-white mt-2 text-center px-4 font-sans transition-opacity duration-500 ${
                fadeOpacity ? "opacity-100" : "opacity-0"
              }`}
            >
              {title}
            </h1>

            {/* Dynamic Tags with Fade-In Effect */}
            <div className="flex flex-wrap justify-center gap-2 mt-3">
              {[job, sex, displayLove, mbti, age]
                .filter((tag) => tag && tag !== "NOT_SPECIFIED")
                .map((tag, index) => (
                  <span
                    key={index}
                    className={`text-xs font-sans text-white font-bold border border-white rounded-full px-3 py-1 transition-opacity duration-500 ${
                      fadeOpacity ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    {tag}
                  </span>
                ))}
            </div>
          </>
        ) : (
          <RingLoader color="#2986dd" size={75} />
        )}
      </div>

      {/* Surrounding Oval Dots */}
      <div className="absolute inset-0 flex items-center justify-center">
        {[...Array(60)].map((_, i) => (
          <span
            key={i}
            className={`absolute animate-pulse ${
              i >= startIndex && i < startIndex + count
                ? "bg-white"
                : "bg-white/50"
            }`}
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "90%",
              transform: `rotate(${i * 6}deg) translate(130px) rotate(-${
                i * 6
              }deg)`,
              animationDelay: `${i * 0.1}s`,
              boxShadow:
                i >= startIndex && i < startIndex + count
                  ? "0 0 8px 4px rgba(255, 255, 255, 0.8)"
                  : "none",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CircleComponent;
