import axios from 'axios';
import API_BASE_URL from '../../config';

// 공지사항 조회
export const getNotices = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/notices`);
        return response.data;
    } catch (error) {
        console.error('Error fetching notices:', error);
        throw error;
    }
};

// 공지사항 생성
export const createNotice = async (notice) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/notices`, notice);
        return response.data;
    } catch (error) {
        console.error('Error creating notice:', error);
        throw error;
    }
};

// 공지사항 수정
export const updateNotice = async (noticeId, notice) => {
    try {   
        const response = await axios.put(`${API_BASE_URL}/notices/${noticeId}`, notice);
        return response.data;
    } catch (error) {
        console.error('Error updating notice:', error);
        throw error;
    }
};

// 공지사항 삭제
export const deleteNotice = async (noticeId) => {
    try {   
        const response = await axios.delete(`${API_BASE_URL}/notices/${noticeId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting notice:', error);
        throw error;
    }
};

// 공지사항 상세 조회
export const getNoticeById = async (noticeId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/notices/${noticeId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching notice by ID:', error);
        throw error;
    }
};

// 공지사항 페이지네이션 조회 (필터 포함)
export const getNoticesByPage = async (page = 1, limit = 10, search = '', startDate = null, endDate = null) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/notices/search`, {
            params: { 
                page, 
                limit, 
                search, 
                startDate,
                endDate
            }
        });
        return {
            notices: response.data.notices,
            total: response.data.total
        };
    } catch (error) {
        console.error('Error fetching notices by page:', error);
        throw error;
    }
};

// 공지사항에서 pinned true인 것만 조회
export const getPinnedNotices = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/notices/pinned`);
        return response.data;
    } catch (error) {
        console.error('Error fetching pinned notices:', error);
        throw error;
    }
};

// 공지사항 생성 또는 수정
export const saveNotice = async (notice, mode, noticeId = null) => {
    try {
        let response;
        if (mode === 'create') {
            response = await axios.post(`${API_BASE_URL}/notices`, notice);
        } else if (mode === 'edit' && noticeId) {
            response = await axios.put(`${API_BASE_URL}/notices/${noticeId}`, notice);
        }
        return response.data;
    } catch (error) {
        console.error(`Error ${mode === 'create' ? 'creating' : 'updating'} notice:`, error);
        throw error;
    }
};
