import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Navigation from '../../components/admin/Navigation';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ChevronDownIcon } from '@heroicons/react/solid';
import calendarIcon from '../../assets/admin/icons/calendar.png';
import handleSortIcon from '../../assets/admin/icons/handleSortIcon.png';
import leftCursor from '../../assets/admin/icons/leftCursor.png';
import rightCursor from '../../assets/admin/icons/rightCursor.png';
import axiosInstance from '../../utils/admin/axiosConfig';

const MemberList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [loginType, setLoginType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isItemLimitDropdownOpen, setIsItemLimitDropdownOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  useEffect(() => {
    fetchMemberData();
  }, [currentPage, itemsPerPage, loginType, searchTerm, startDate, endDate, sortConfig]);

  const fetchMemberData = async () => {
    try {
      // 날짜 형식 변환 함수
      const formatDate = (date) => {
        if (!date) return undefined;
        const d = new Date(date);
        const tzOffset = d.getTimezoneOffset() * 60000;
        const localDate = new Date(d.getTime() - tzOffset);
        return localDate.toISOString().split('T')[0];
      };

      const adjustedEndDate = endDate ? new Date(endDate) : null;
      if (adjustedEndDate) {
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
      }

      const params = {
        page: currentPage,
        limit: itemsPerPage,
        loginType: loginType || undefined,
        search: searchTerm || undefined,
        startDate: formatDate(startDate),
        endDate: formatDate(adjustedEndDate)
      };

      const response = await axiosInstance.get('/auth/users', { params });
      
      // 현재 페이지의 데이터만 정렬
      let currentPageData = response.data.data;
      if (sortConfig.key) {
        currentPageData = [...currentPageData].sort((a, b) => {
          if (sortConfig.direction === 'ascending') {
            return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
          } else {
            return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
          }
        });
      }

      setData(currentPageData);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error('회원 데이터 불러오기 실패:', error.response || error);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchMemberData();
  };

  const handleLoginTypeChange = (event) => {
    setLoginType(event.target.value);
    setCurrentPage(1);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map(item => item.userid));
    }
  };

  const handleRowClick = (userid) => {
    navigate(`/member-editor/${userid}`);
  };

  const handleItemLimitChange = (limit) => {
    setItemsPerPage(limit);
    setCurrentPage(1);
  };

  const handleExcelDownload = async () => {
    try {
      // 다운로드 시작 알림
      alert('데이터를 다운로드하고 있습니다. 잠시만 기다려주세요...');
      
      const allData = await fetchAllData();
      
      if (allData.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      // 메모리 최적화를 위해 청크 단위로 처리
      const chunkSize = 1000;
      const chunks = [];
      
      for (let i = 0; i < allData.length; i += chunkSize) {
        const chunk = allData.slice(i, i + chunkSize).map(item => ({
          '구분': item.loginType || '-',
          '이름': item.username || '-',
          '휴대폰 번호': item.phoneNumber || '-',
          '닉네임': item.nickname || '-',
          'Native': item.nativeLanguage || '-',
          'Target': item.targetLanguage || '-',
          '유료회원': item.paymentUsed ? 'O' : '-',
          '추천인': item.recommender || '-',
          '가입일시': new Date(item.createdAt).toLocaleString('ko-KR'),
          '상태': item.deletedAt ? '탈퇴' : '정상'
        }));
        chunks.push(chunk);
      }

      // 청크를 하나의 워크시트로 결합
      const ws = XLSX.utils.json_to_sheet([]);
      chunks.forEach((chunk, index) => {
        XLSX.utils.sheet_add_json(ws, chunk, {
          origin: index === 0 ? 'A1' : -1,
          skipHeader: index > 0
        });
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "회원목록");
      
      // 파일 이름에 날짜 추가
      const date = new Date().toISOString().split('T')[0];
      XLSX.writeFile(wb, `회원목록_${date}.xlsx`);
      
      alert('엑셀 다운로드가 완료되었습니다.');
    } catch (error) {
      console.error('엑셀 다운로드 실패:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  const fetchAllData = async () => {
    try {
      let allData = [];
      let page = 1;
      const limit = 1000; // 한 번에 가져오는 데이터 수를 늘림
      let hasMore = true;
      const maxRetries = 3; // 재시도 횟수 설정
      
      while (hasMore) {
        let retryCount = 0;
        let success = false;
        
        while (!success && retryCount < maxRetries) {
          try {
            const formatDate = (date) => {
              if (!date) return undefined;
              const d = new Date(date);
              const tzOffset = d.getTimezoneOffset() * 60000;
              const localDate = new Date(d.getTime() - tzOffset);
              return localDate.toISOString().split('T')[0];
            };

            const params = {
              page,
              limit,
              loginType: loginType || undefined,
              search: searchTerm || undefined,
              startDate: formatDate(startDate),
              endDate: formatDate(endDate)
            };

            const response = await axiosInstance.get('/auth/users', { params });
            
            if (response.data && Array.isArray(response.data.data)) {
              allData = [...allData, ...response.data.data];
              
              // 진행 상황 로그
              console.log(`Fetched ${allData.length} / ${response.data.total} records`);
              
              if (allData.length >= response.data.total) {
                hasMore = false;
              }
              success = true;
            } else {
              throw new Error('Invalid response format');
            }
          } catch (error) {
            retryCount++;
            if (retryCount === maxRetries) {
              console.error(`Failed to fetch page ${page} after ${maxRetries} attempts:`, error);
              throw error;
            }
            // 재시도 전 잠시 대기
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
        page++;
      }

      return allData;
    } catch (error) {
      console.error('전체 회원 데이터 불러오기 실패:', error);
      throw error;
    }
  };

  const selectedDropdownStyle = {
    height: '36px',
    padding: '4px 14px',
    borderRadius: '12px',
    border: '1px solid #E2E8EF',
    fontFamily: 'Pretendard',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  };

  // 정렬 핸들러 (정렬 기준 및 방향 설정)
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    
    // 현재 페이지의 데이터만 정렬
    const currentPageData = [...data].sort((a, b) => {
      if (direction === 'ascending') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
    
    setData(currentPageData);
  };

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div style={{ padding: '26px 30px' }}>
        
        <h1 className="font-bold uppercase" style={{ width: '140px', height: '28px', fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700, textAlign: 'left' }}>
          회원목록
        </h1>

        <div className="" style={{ margin: '26px 0px 20px 0' }}>
          <div className="flex items-center space-x-3">
            <select 
              value={loginType} 
              onChange={handleLoginTypeChange} 
              className="w-[100px] h-[36px]" 
              style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '4px 14px', borderRadius: '12px', color: '#A9B6CA'}}
            >
              <option value="">전체</option>
              <option value="local">일반</option>
              <option value="kakao">KAKAO</option>
              <option value="apple">APPLE</option>
            </select>

            <div className="flex">
              <input
                type="text"
                placeholder="이름 또는 닉네임 검색"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="h-[36px] w-[250px]"
                style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '10px 14px', borderRadius: '12px', color: '#A9B6CA' }}
              />
              {/* <button onClick={handleSearch} className="bg-[#3677F6] text-white" style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, width: '57px', height: '36px', padding: '4px 4px', borderRadius: '0px 12px 12px 0px' }}>
                검색
              </button> */}
            </div>

            <div 
              className="relative flex items-center h-[36px] w-[250px] bg-white cursor-pointer" 
              style={{ borderRadius: '12px', padding: '0px 8px' }}
              onClick={() => {
                const datePickerInput = document.querySelector('.react-datepicker__input-container input');
                if (datePickerInput) {
                  datePickerInput.click();
                }
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  if (start) {
                    const startOfDay = new Date(start);
                    startOfDay.setHours(0, 0, 0, 0);
                    setStartDate(startOfDay);
                  } else {
                    setStartDate(null);
                  }
                  
                  if (end) {
                    const endOfDay = new Date(end);
                    endOfDay.setHours(23, 59, 59, 999);
                    setEndDate(endOfDay);
                  } else {
                    setEndDate(null);
                  }
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                isClearable={true}
                dateFormat="yyyy-MM-dd"
                placeholderText="기간 입력"
                className="w-full h-full"
                style={{ 
                  fontFamily: 'Pretendard', 
                  fontSize: '14px', 
                  fontWeight: 500,
                  border: 'none',
                  color: '#A9B6CA',
                  cursor: 'pointer',
                  pointerEvents: 'none'
                }}
              />
              <img 
                src={calendarIcon} 
                alt="Calendar Icon" 
                className="absolute right-2 w-7 h-6"
                style={{ pointerEvents: 'none' }}
              />
            </div>
          </div>
        </div>

        {/* 목록 테이블 섹션 */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <div className="text-lg mt-5 mb-7 ml-5">
              <label className="text-xl font-bold text-[#0E111F] mr-5" style={{ fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700 }}>
                목록
              </label>
              <label 
                className="text-sm font-medium text-[#0E111F]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                검색결과 {totalItems}개 | 선택 {selectedRows.length}개 | 현재 페이지 {currentPage}
              </label>
            </div>

            
            <div className="flex justify-end items-center">
              {/* 항목 수 선택 */}
              <div className="relative">
                <div
                  style={selectedDropdownStyle}
                  onClick={() => setIsItemLimitDropdownOpen(!isItemLimitDropdownOpen)}
                >
                  <span>{`${itemsPerPage}개씩 보기`}</span>
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                </div>
                {isItemLimitDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                    {[5, 10, 20].map((limit) => (
                      <div
                        key={limit}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          handleItemLimitChange(limit);
                          setIsItemLimitDropdownOpen(false);
                        }}
                      >
                        {`${limit}개씩 보기`}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* 엑셀 다운로드 버튼 추가 */}
              <div className="flex justify-end m-5">
                <button 
                  onClick={handleExcelDownload}
                  className="flex items-center justify-center"
                  style={{
                    width: '110px',
                    height: '36px',
                    padding: '4px 14px',
                    borderRadius: '12px',
                    backgroundColor: '#666B81',
                    color: '#FFFFFF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 700,
                  }}
                >
                  엑셀 다운로드
                </button>
              </div>
            </div>
          </div>

          <table className="min-w-full divide-y divide-gray-200 shadow-md">
            <thead className="bg-[#E2E8EF]" style={{ height: '40px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#666B81' }}>
              <tr>
                <th className='text-center w-16'>
                  {/* 모든 항목 선택/해제 체크박스 */}
                  <input 
                    type="checkbox" 
                    onChange={handleSelectAll} 
                    checked={selectedRows.length === data.length}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-16">No.</th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-22" 
                  onClick={() => handleSort('loginType')} 
                >
                  구분<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-22">
                  이름
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-30">
                  휴대폰 번호
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-30" 
                  onClick={() => handleSort('nickname')} 
                >
                  닉네임<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-22">Native</th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-22">Target</th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-22">
                  유료회원
                </th>
                <th className="text-center font-medium text-gray-500 tracking-wider w-22">추천인</th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44" 
                  onClick={() => handleSort('createdAt')} 
                >
                  가입일시<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-22" 
                >
                  상태
                </th>
              </tr>
            </thead>
            <tbody 
              className="bg-white divide-y divide-gray-200"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}
            >
              {data.map((item, index) => (
                <tr key={item.userid} className='hover:bg-gray-100 transition duration-150 ease-in-out h-[40px]'>
                  <td className="whitespace-no-wrap text-center w-16">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(item.userid)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectRow(item.userid);
                      }}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                  </td>
                  <td className="whitespace-no-wrap text-center" onClick={() => handleRowClick(item.userid)}>{index + 1}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.loginType}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.username || '-'}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.phoneNumber || '-'}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.nickname || '-'}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.nativeLanguage || '-'}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.targetLanguage || '-'}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.paymentUsed ? 'O' : '-'}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.recommender || '-'}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.createdAt ? new Date(item.createdAt).toLocaleString('ko-KR') : '-'}</td>
                  <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.userid)}>{item.deletedAt ? '탈퇴' : '정상'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* 페이지네이션 컴포넌트 */}
        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel={
              <img src={leftCursor} alt="Left Cursor" className="w-4 h-4" />
            }
            nextLabel={
              <img src={rightCursor} alt="Right Cursor" className="w-4 h-4" />
            }
            breakLabel={'...'}
            pageCount={Math.ceil(totalItems / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination flex space-x-2 items-center'}
            activeClassName={'text-[#3677F6] font-bold'}
            breakClassName={'text-[#A9B6CA]'}
            disabledClassName={'text-[#C5D0DD] cursor-not-allowed'}
          />
        </div>
      </div>
    </div>
  );
};

export default MemberList;