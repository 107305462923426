import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GnbHome from "./components/GnbHome";
import CircleComponent from "./CircleComponent";
import CircleComponent2 from "./CircleComponent2";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { LanguageContext } from "./LanguageContext";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode as jwt_decode } from "jwt-decode";
import { setUserInfo } from "./actions";
import VipModal from "./VipModal";
import api from "./api"; // Import your custom axios instance
import { setManageID, setType, setImageUrl, setWhere } from "./actions";
import { useDifficulty } from "./DifficultyContext";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal2, setShowModal2] = useState(false);
  const { translations, switchLanguage, language } =
    useContext(LanguageContext);
  const { difficulty, setDifficulty } = useDifficulty();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [attendanceCount, setAttendanceCount] = useState("");
  const [scriptCount, setScriptCount] = useState("");
  const [userQuizRating, setUserQuizRating] = useState("");
  const [initialTime, setInitialTime] = useState(72 * 3600);
  const [userId, setUserId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [vip, setVip] = useState(false);
  const [vip3, setVip3] = useState(false);
  const [nickname, setNickname] = useState("");
  const [images, setImages] = useState([]);
  const [links, setLinks] = useState([]);
  const [showVipModal, setShowVipModal] = useState(false); // Control VIP modal visibility
  const [endDate, setEndDate] = useState(""); // Store the VIP end date
  const handleModalClose = () => setShowVipModal(false);
  const [recommendation, setRecommendation] = useState(null);
  const [showText, setShowText] = useState(false); // New state to control text visibility
  const [isSwipeEnabled, setIsSwipeEnabled] = useState(true);
  const [freeDay, setFreeDay] = useState(null);

  const handleGoStudy = (item) => {
    sessionStorage.removeItem("elapsedTime");
    dispatch(setManageID(item.id));
    dispatch(setImageUrl(item.imageurl));
    dispatch(setWhere("home"));
    sessionStorage.removeItem("starscoreB");
    sessionStorage.removeItem("starscoreS");
    const fetchData = async () => {
      try {
        const languages = ["kor", "eng"];
        const difficulties = ["easyId", "normalId", "difficultId"];
        const fetchedLis = {};

        for (const language of languages) {
          fetchedLis[language] = {};
          const languageItem = item.customizes.find(
            (cust) => cust.language === language
          );

          if (languageItem) {
            for (const difficulty of difficulties) {
              const id = languageItem[difficulty];

              if (id) {
                const response = await fetch(
                  `${apiUrl}/customize_script/${id}`
                );
                const data = await response.json();

                if (response.ok) {
                  console.log(
                    `Fetched data for ${language} - ${difficulty}:`,
                    data
                  );
                  fetchedLis[language][difficulty] = data;
                }
              }
            }
          }
        }

        const countUniqueTypes = (data) => {
          const uniqueTypes = new Set(
            data.map((item) => item.type).filter((type) => type !== "")
          );
          return uniqueTypes.size;
        };

        const fetchQuizData = async () => {
          try {
            const response = await fetch(
              `${apiUrl}/customize_quiz?scriptId=${fetchedLis[language][difficulty].id}`
            );
            const data = await response.json();
            return countUniqueTypes(data);
          } catch (err) {
            console.error("Fetch error:", err);
          }
        };

        const pgs = (await fetchQuizData()) + 3;

        dispatch(setType("mbti"));
        navigate("/studyonboard", {
          state: { script: fetchedLis, item: item, pgs: pgs },
        });
      } catch (error) {}
    };

    fetchData();
  };

  const fetchRandomRecommendation = async () => {
    try {
      const response = await api.get("/customize/recommend/random");
      setRecommendation(response.data);

      setTimeout(() => setShowText(true), 1000); // Adjust delay as needed
    } catch (error) {
      throw error; // Optionally rethrow the error to handle it elsewhere
    }
  };

  // Example of how to use the function
  useEffect(() => {
    fetchRandomRecommendation();
  }, []);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  // Fetch images and links from the API when the component mounts
  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        const response = await fetch(`${apiUrl}/appsettings/?id=1`);
        const data = await response.json();
        // Update state with fetched images and links
        setFreeDay(data[0].freeDay);
        const images = [
          data[0].bannerImage1,
          data[0].bannerImage2,
          data[0].bannerImage3,
          data[0].bannerImage4,
          data[0].bannerImage5,
        ].filter((image) => image !== "" && image !== null);

        const links = [
          data[0].bannerLink1,
          data[0].bannerLink2,
          data[0].bannerLink3,
          data[0].bannerLink4,
          data[0].bannerLink5,
        ].filter((link) => link !== "" && link !== null);

        // Set state with all images and links, including null values
        setImages(images);
        setLinks(links);
      } catch (error) {}
    };

    fetchSliderData();
  }, []); // Empty dependency array to run only once when the component mounts

  // Handler functions for navigation
  const goToBookMark = () => {
    sessionStorage.setItem("whereBookmark", "home");
    navigate("/bookmark");
  };
  const goToNote = () => {
    sessionStorage.setItem("whereNote", "home");
    navigate("/note");
  };

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: "slick-dots", // Maintain default class for positioning
    arrows: false,
  };

  const handleSwipeLeft = () => {
    if (isSwipeEnabled) {
      navigate("/study");
    }
  };

  const handleSwipeRight = () => {};

  UseSwipe(handleSwipeLeft, handleSwipeRight, isSwipeEnabled);

  const fetchInfo = async (accessToken) => {
    try {
      const response = await fetch(`${apiUrl}/payment/usage-period`, {
        method: "GET", // Use DELETE method
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const result = await response.json();

        const currentDate = new Date(); // Get the current date
        const endDate = new Date(result.endDate); // Convert endDate from response to Date object
        sessionStorage.setItem("endDate", endDate);
        setEndDate(result.endDate); // Store the end date of VIP membership
        // Check if the endDate has not expired
        if (currentDate <= endDate) {
          sessionStorage.setItem("vip", "true");
          setVip(true);
        } else {
          sessionStorage.setItem("vip", "false"); // Mark the user as not VIP if the date has expired
          setVip(false);
        }
        setVip3(true);
      } else {
        // Handle error response
      }
    } catch (error) {
      navigate("/welcome");
    }
    try {
      const response = await fetch(`${apiUrl}/auth/user-info`, {
        method: "GET", // Use DELETE method
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
          "Content-Type": "application/json",
        },
        // You can include a body if required by the API, but often DELETE requests don't have a body
        // body: JSON.stringify({ someData: "value" }),
      });

      if (response.ok) {
        // navigate("/welcome");
        const result = await response.json();
        result["nativeLanguage"] = result["nativeLanguage"].toLowerCase();
        result["targetLanguage"] = result["targetLanguage"].toLowerCase();
        console.log(result);
        localStorage.setItem("useridR", result.id);
        dispatch(setUserInfo(result));
        localStorage.setItem("userid", result.userid);

        setAttendanceCount(result.attendanceCount);
        setScriptCount(result.scriptCount);
        setUserQuizRating(result.userQuizRating);

        // Calculate the remaining time for the 3-day freemembership in seconds
        const createdAt = new Date(result.createdAt); // Account creation time
        const currentTime = new Date(); // Current time
        const diffInMilliseconds = currentTime - createdAt; // Time difference in milliseconds

        const freeMembershipDuration = freeDay * 24 * 60 * 60 * 1000; // 3 days in milliseconds
        const remainingMilliseconds =
          freeMembershipDuration - diffInMilliseconds; // Remaining time in milliseconds
        const remainingSeconds = Math.max(remainingMilliseconds / 1000, 0); // Convert to seconds, ensure it's non-negative

        console.log(
          `Free membership time remaining: ${Math.floor(
            remainingSeconds
          )} seconds`
        );

        setInitialTime(Math.floor(remainingSeconds)); // Set the initial time for countdown in seconds
        setTime(Math.floor(remainingSeconds)); // Set the initial time for countdown in seconds
        if (Math.floor(remainingSeconds) > 0) {
          sessionStorage.setItem("vip2", "true");
        } else {
          sessionStorage.removeItem("vip2");
        }

        if (sessionStorage.getItem("autoFlag") === "true") {
          sessionStorage.setItem("autoFlag", "");
          // Show modal with the nickname
          setNickname(result.nickname);
          setShowModal(true);
          // Hide modal after 1 second
          setTimeout(() => {
            setShowModal(false);
          }, 1000);
        }
      } else {
        // Handle error response
      }
    } catch (error) {
      // Handle request error
    }
    //멤버십 여부
  };

  useEffect(() => {
    // This runs immediately after the component mounts
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      setAccessToken(token); // Save the token to state
    }
  }, []); // The empty dependency array ensures this only runs once

  useEffect(() => {
    if (freeDay !== null && sessionStorage.getItem("accessToken")) {
      fetchInfo(sessionStorage.getItem("accessToken"));
    }
  }, [accessToken, freeDay]);

  const [time, setTime] = useState(0);

  useEffect(() => {
    // Set up an interval to update the time every second
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1); // Decrement the time by 1 second
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [initialTime]);

  // Convert seconds into HH:MM:SS format
  const formatTime = (seconds) => {
    const days = Math.floor(seconds / 86400); // 1 day = 86400 seconds
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${String(days).padStart(2, "0")}:${String(hours).padStart(
      2,
      "0"
    )}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const goBuy = async () => {
    navigate("/purchase");
  };
  return (
    <div
      id="swipe-container"
      className="flex justify-center w-full overflow-y-auto bg-[#faf8f6] dark:bg-[#121418]"
      style={{ height: "100vh" }}
    >
      <div className="relative w-full  overflow-x-hidden ">
        {/* Modal Component */}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded shadow-lg">
              <h2>Welcome Back, {nickname}!</h2>
            </div>
          </div>
        )}
        {/* Navigation Bar */}
        <GnbHome></GnbHome>

        <div className="w-full h-[654px] flex items-center justify-center">
          {!darkMode ? (
            <>
              <div className="absolute left-0 top-0 w-full h-[600px] bg-[linear-gradient(180deg,rgba(47,157,235,1)_0%,rgba(54,119,246,0)_100%)]"></div>
              <div className="absolute left-0 top-0 w-full h-[100%] bg-[linear-gradient(180deg,#ffffff00_0%,#ffffff1a_100%)] filter-[backdrop-blur(75px)]"></div>
            </>
          ) : null}

          <div className="-mt-[200px]">
            {attendanceCount === 1 ? (
              <CircleComponent count={attendanceCount} />
            ) : recommendation ? (
              <CircleComponent2
                title={
                  language === "kor"
                    ? recommendation.customizes.filter(
                        (item) => item.language === "kor"
                      )[0].title ?? "예시 제목"
                    : recommendation.customizes.filter(
                        (item) => item.language === "eng"
                      )[0].title ?? "Sample Title"
                }
                sex={recommendation.sex}
                age={recommendation.age}
                job={recommendation.job}
                love={recommendation.love}
                mbti={recommendation.mbti}
                count={attendanceCount}
                showText={showText} // Pass `showText` to control animation
                onClick={() => {
                  handleGoStudy(recommendation);
                }}
              />
            ) : (
              <CircleComponent2 count={attendanceCount} />
            )}
          </div>
        </div>

        <div className="absolute left-1/2 top-[380px] w-[328px] flex flex-col items-start justify-start gap-[21px] transform -translate-x-1/2 pb-[70px]">
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
            <div
              className="relative w-[156px] h-[131px] shrink-0 bg-[#fff] dark:bg-[#22232a] rounded-[12px] overflow-hidden"
              onClick={goToBookMark}
            >
              <div className="absolute -translate-x-1/2 left-1/2 top-[103px] text-[14px] leading-[100%] font-sans font-medium text-[#000] dark:text-[#fff] whitespace-nowrap">
                {translations.BOOKMARK}
              </div>
              <img
                className="absolute -translate-x-1/2 left-1/2 top-[10px]"
                width="90px"
                src="bookmark_img.svg"
              ></img>
            </div>
            <div
              className="relative w-[156px] h-[131px] shrink-0 bg-[#fff] dark:bg-[#22232a] rounded-[12px] overflow-hidden"
              onClick={goToNote}
            >
              <div className="absolute -translate-x-1/2 left-1/2 top-[103px] text-[14px] leading-[100%] font-sans font-medium text-[#000] dark:text-[#fff] text-center whitespace-nowrap">
                {translations.INCORRECT_ANSWER_NOTES}
              </div>
              <img
                className="absolute left-[55px] top-[26px]"
                width="45"
                height="57"
                src="Group 126115505912_119.png"
              ></img>
            </div>
          </div>
          {/* Carousel Section */}
          {images.length !== 0 ? (
            <div
              className="w-full h-[56px] mb-[25px] flex justify-center items-center"
              onMouseEnter={() => setIsSwipeEnabled(false)}
              onMouseLeave={() => setIsSwipeEnabled(true)}
              onTouchStart={() => setIsSwipeEnabled(false)}
              onTouchEnd={() => setIsSwipeEnabled(true)}
            >
              <Slider {...sliderSettings} className="w-full h-full">
                {images.map((image, index) => (
                  <div key={index} className="w-full h-[80px]" id="slider">
                    <a
                      href={links[index] || "#"} // Fallback to '#' if no link is provided
                    >
                      <img
                        src={apiUrl + "/upload" + image}
                        alt={`Carousel slide ${index}`}
                        className="w-full h-full object-cover object-center rounded-lg"
                      />
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
          ) : null}
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
            {!vip && time > 0 && (
              <div className="self-stretch flex flex-row items-center justify-between">
                <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] whitespace-nowrap">
                  {translations.home4}
                </div>
              </div>
            )}
            {!vip && time > 0 && (
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch h-[100px] shrink-0 flex flex-col items-center justify-center gap-[10px] bg-[#fff] dark:bg-[#22232a] rounded-tl-[12px] rounded-tr-[12px] rounded-br-0 rounded-bl-0">
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    {translations.home5}
                  </div>
                  <div className="text-[30px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] whitespace-nowrap">
                    {formatTime(time)}
                  </div>
                </div>
                <div
                  className="self-stretch h-[48px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-tl-0 rounded-tr-0 rounded-br-[12px] rounded-bl-[12px]"
                  onClick={goBuy}
                >
                  <div className="text-[14px] leading-[100%] font-sans font-bold text-[#fff] whitespace-nowrap">
                    {translations.home6}
                  </div>
                </div>
              </div>
            )}
            <div className="w-[328px] flex flex-row items-center justify-between">
              <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] whitespace-nowrap">
                {translations._LESSON_STATISTICS}
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
              <div className="relative self-stretch h-[86px] shrink-0 bg-[#fff] dark:bg-[#22232a] rounded-[12px] overflow-hidden">
                <img
                  className="absolute left-[20px] top-[15px]"
                  width="50"
                  height="55"
                  src="Frame 126115527012_148.png"
                ></img>
                <div className="absolute left-[80px] top-[26px] w-[137px] flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
                    {translations.ATTENDANCE_DAYS}
                  </div>
                  <div className="self-stretch text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                    {translations.home1}
                  </div>
                </div>
                <div className="absolute -translate-y-1/2 right-[20px] top-1/2 flex flex-row items-end justify-end gap-[2px]">
                  <div
                    className="text-[40px] leading-[80%] font-sans font-[500] whitespace-nowrap"
                    style={{
                      background:
                        "linear-gradient(64.95deg, #61e3eb 0%, #3677f6 100%)",
                      backgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    {attendanceCount ? attendanceCount : "-"}
                  </div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    {translations.days}
                  </div>
                </div>
              </div>
              <div className="relative self-stretch h-[86px] shrink-0 bg-[#fff] dark:bg-[#22232a] rounded-[12px] overflow-hidden">
                <img
                  className="absolute left-[20px] top-[15px]"
                  width="50"
                  height="55"
                  src="Frame 126115527112_164.png"
                ></img>
                <div className="absolute left-[80px] top-[26px] w-[127px] flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
                    {translations.MY_ACCOMPLISHMENT}
                  </div>
                  <div className="self-stretch text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                    {translations.home2}
                  </div>
                </div>
                <div className="absolute -translate-y-1/2 right-[20px] top-1/2 flex flex-row items-end justify-center gap-[2px]">
                  <div
                    className="text-[40px] leading-[80%] font-sans font-[500] whitespace-nowrap"
                    style={{
                      background:
                        "linear-gradient(64.95deg, #61e3eb 0%, #3677f6 100%)",
                      backgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    {scriptCount ? scriptCount : "-"}
                  </div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    {translations.chapters}
                  </div>
                </div>
              </div>
              <div className="relative self-stretch h-[86px] shrink-0 bg-[#fff] dark:bg-[#22232a] rounded-[12px] overflow-hidden  mb-[30px]">
                <img
                  className="absolute -translate-y-1/2 left-[20px] top-1/2"
                  width="50"
                  height="55"
                  src="Frame 126115527212_179.png"
                ></img>
                <div className="absolute left-[80px] top-[26px] w-[71px] flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
                    {translations.ACCURACY_RATE}
                  </div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    {translations.home3}
                  </div>
                </div>
                <div className="absolute -translate-y-1/2 right-[20px] top-1/2 flex flex-row items-end justify-center gap-[2px]">
                  <div
                    className="text-[40px] leading-[80%] font-sans font-[500] whitespace-nowrap"
                    style={{
                      background:
                        "linear-gradient(64.95deg, #61e3eb 0%, #3677f6 100%)",
                      backgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    {userQuizRating ? (userQuizRating * 100) | 0 : "-"}
                  </div>
                  <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                    %
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-[16px] top-[20px] w-[130px] h-[34px] flex">
          <img
            className=""
            width="99"
            height="30"
            src="_레이어_112_77.png"
          ></img>
          <div className="absolute left-0 top-[45px] w-[130px] flex flex-row items-center justify-center py-[6px] px-[8px] bg-[#fff] dark:bg-[#464a5a] rounded-[12px]">
            <div className="text-[12px] leading-[100%] font-sans font-bold text-[#3677f6] dark:text-[#d5dce4] text-center whitespace-nowrap">
              {parseInt(attendanceCount, 10) === 1
                ? translations.WELCOME_NICE_TO_MEET_YOU
                : language === "kor"
                ? `출석 ${attendanceCount ? attendanceCount : "-"}일 달성!`
                : `${attendanceCount ? attendanceCount : "-"} Days We've Met!`}
            </div>
          </div>
        </div>

        <div className="absolute right-[16px] top-[20px] w-[112px] h-[36px] flex flex-row items-center justify-between">
          {/* First item (text and images) */}
          <div
            className="flex flex-row items-center justify-start"
            onClick={() => {
              if (vip) {
                setShowVipModal(true); // Show the modal if VIP
              } else {
                goBuy(); // Navigate to the purchase page if not VIP
              }
            }}
          >
            {darkMode ? (
              <img width="10" height="28" src="t_l_b.png" alt="icon" />
            ) : (
              <img width="10" height="28" src="t_l_w.png" alt="icon" />
            )}
            <div className="self-stretch bg-[#fff] dark:bg-[#31333e] flex flex-row items-center justify-center">
              <div
                style={{ fontSize: "10px" }}
                className={`leading-[100%] font-sans font-bold text-[#3677f6] dark:text-[#666b81] whitespace-nowrap ${
                  vip3 ? "opacity-1" : "opacity-0"
                }`}
              >
                {vip ? "PREMIUM" : translations.buyticket}
              </div>
            </div>
            {darkMode ? (
              <img width="10" height="28" src="t_r_b.png" alt="icon" />
            ) : (
              <img width="10" height="28" src="t_r_w.png" alt="icon" />
            )}
          </div>
          <div className="w-[20px]"></div>
          {/* Question Mark Icon positioned as superscript */}
          <div
            className="absolute -right-3 -top-1 w-4 h-4 flex items-center justify-center bg-indigo-500 text-white rounded-full text-xs cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            onClick={() => setShowModal2(true)}
          >
            ?
          </div>
          {/* Second item (circle with text "Aa") */}
          <LanguageSwitcher2></LanguageSwitcher2>
        </div>
        {vip && showVipModal && (
          <VipModal endDate={endDate} onClose={handleModalClose} />
        )}
        {/* Modal2 */}
        {showModal2 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-4 w-[300px] text-center shadow-lg transform transition-transform duration-500 ease-out scale-95 origin-top">
              <p className="text-sm font-semibold mb-4 text-center">
                파스텔라는 학습 효과를 극대화하기 위해 네이티브와 타겟 간 전환
                기능을<br></br> 제공합니다
              </p>
              <p className="text-sm font-semibold mb-8">
                전환 버튼을 누르시면 <br></br> 페이지가 타겟으로 변환됩니다
              </p>
              <h3 className="text-xs font-['blue'] font-semibold">
                *일부 결제 상품이나 고유 데이터는<br></br> 변환에서 제외됩니다
              </h3>
              <button
                onClick={() => setShowModal2(false)}
                className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none"
              >
                확인
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
