import React from "react";
import { useState, useEffect, useContext, useCallback } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setManageID, setType, setImageUrl, setWhere } from "./actions";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { IoIosArrowBack } from "react-icons/io";
import { FaTimes } from "react-icons/fa";
import { useDifficulty } from "./DifficultyContext";

const PsychologyStudyList = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const keywords = useSelector((state) => state.keywords);
  const lis = useSelector((state) => state.lis);
  const { translations, language } = useContext(LanguageContext);
  const [data, setData] = useState({});
  const [language2, setLanguage2] = useState("");
  const [toggleFilter, setToggleFilter] = useState(true);
  const [visibleItems, setVisibleItems] = useState(10);
  const { difficulty, setDifficulty } = useDifficulty();
  const [sortConfig, setSortConfig] = useState({
    key: sessionStorage.getItem("sortkey") ?? "latest",
    direction: sessionStorage.getItem("sortdir") ?? "ascending",
  });
  const [sortedLis, setSortedLis] = useState([]);

  const navigate = useNavigate();

  const handleGoBack = () => {
    if (sessionStorage.getItem("aswh") === "starscore") {
      sessionStorage.removeItem("aswh");
      navigate(-1);
    } else navigate("/psychologystudy");
  };

  useEffect(() => {
    setToggleFilter((prevToggle) => !prevToggle);
    if (language === "eng") {
      setLanguage2("kor");
    } else {
      setLanguage2("eng");
    }
  }, [language]);

  const handleGoStudy = (item) => {
    sessionStorage.removeItem("elapsedTime");
    sessionStorage.setItem("link", item.link);
    sessionStorage.setItem("sortkey", sortConfig.key);
    sessionStorage.setItem("sortdir", sortConfig.direction);
    sessionStorage.setItem(
      "itemId",
      item.psychologys.filter(
        (customizes) => customizes.language === language
      )[0].id
    );
    dispatch(setManageID(item.id));
    dispatch(setImageUrl(item.imageurl));
    sessionStorage.removeItem("starscoreB");
    sessionStorage.removeItem("starscoreS");
    dispatch(setWhere("study"));
    const fetchData = async () => {
      try {
        const languages = ["kor", "eng"];
        const difficulties = ["easyId", "normalId", "difficultId"];
        const fetchedLis = {};

        for (const language of languages) {
          fetchedLis[language] = {};
          const languageItem = item.psychologys.find(
            (cust) => cust.language === language
          );

          if (languageItem) {
            for (const difficulty of difficulties) {
              const id = languageItem[difficulty];

              if (id) {
                const response = await fetch(
                  `${apiUrl}/psychology_script/${id}`
                );
                const data = await response.json();

                if (response.ok) {
                  console.log(
                    `Fetched data for ${language} - ${difficulty}:`,
                    data
                  );
                  fetchedLis[language][difficulty] = data;
                }
              }
            }
          }
        }

        const countUniqueTypes = (data) => {
          const uniqueTypes = new Set(
            data.map((item) => item.type).filter((type) => type !== "")
          );
          return uniqueTypes.size;
        };

        const fetchQuizData = async () => {
          try {
            const response = await fetch(
              `${apiUrl}/psychology_quiz?scriptId=${fetchedLis[language][difficulty].id}`
            );
            const data = await response.json();
            return countUniqueTypes(data);
          } catch (err) {
            console.error("Fetch error:", err);
          }
        };

        const pgs = (await fetchQuizData()) + 3;

        dispatch(setType("psychology"));
        navigate("/studyonboard", {
          state: { script: fetchedLis, item: item, pgs: pgs },
        });
      } catch (error) {}
    };

    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/3`);
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {}
    };

    fetchData();
  }, [apiUrl]);

  const findItemById = (id, node) => {
    if (!node) return null;

    // Check if the current node's id matches the id being searched
    if (node.id === id) return node;

    // Recursively check through children if available
    if (node.children) {
      for (const child of node.children) {
        const result = findItemById(id, child);
        if (result) return result;
      }
    }

    return null;
  };
  const sortData = useCallback(
    (dataToSort) => {
      const sortedData = [...dataToSort];
      if (sortConfig.key === "latest") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? b.id - a.id
            : a.id - b.id;
        });
      } else if (sortConfig.key === "alphabetical") {
        sortedData.sort((a, b) => {
          const aTitle =
            a.psychologys.find((cust) => cust.language === language)?.title ||
            "";
          const bTitle =
            b.psychologys.find((cust) => cust.language === language)?.title ||
            "";
          return sortConfig.direction === "ascending"
            ? aTitle.localeCompare(bTitle)
            : bTitle.localeCompare(aTitle);
        });
      } else if (sortConfig.key === "rating") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? a.averageRating - b.averageRating
            : b.averageRating - a.averageRating;
        });
      }

      return sortedData;
    },
    [sortConfig, language]
  );

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  useEffect(() => {
    const sortedData = sortData(lis);

    setSortedLis(sortedData);
  }, [lis, sortConfig, sortData]);

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6] dark:bg-[#121418]">
        <header className="flex items-center justify-between py-4 px-4">
          <div className="flex items-center">
            <IoIosArrowBack
              color={darkMode ? "white" : "black"}
              size={30}
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] dark:text-[#fff] text-center">
            Psychology
          </h1>
          {darkMode ? (
            <LanguageSwitcher2></LanguageSwitcher2>
          ) : (
            <LanguageSwitcher></LanguageSwitcher>
          )}
        </header>

        <div
          className={`w-full h-[44px] flex items-center justify-between px-4 ${
            darkMode
              ? "bg-[#22232a]"
              : "bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)]"
          }`}
        >
          <div className="flex items-center gap-[8px]">
            <div className="flex items-center justify-center py-[5px] px-[8px] bg-[#fff] dark:bg-[#31333e] rounded-full">
              <div className="text-[12px] leading-[100%] font-sans font-bold text-[#3677f6] dark:text-[#a9b6ca] whitespace-nowrap">
                KEYWORD
              </div>
            </div>
            <div className="flex items-center gap-[4px]">
              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
                {keywords
                  .filter((_, index) => {
                    if (toggleFilter) {
                      return index % 2 !== 0;
                    } else {
                      return index % 2 === 0;
                    }
                  })
                  .map((keyword, index) => (
                    <React.Fragment key={index}>
                      <div className="text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
                        {keyword}
                      </div>
                      {index < keywords.length / 2 - 1 && (
                        <div className="w-[2px] h-[2px] bg-[#fff] rounded-full"></div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="w-[95%] mx-auto">
          <div className="flex items-center justify-end gap-[5px] mb-[10px] mt-[10px]">
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "latest"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("latest")}
            >
              {translations.ORDER_DATE}{" "}
              {sortConfig.key === "latest" &&
                (sortConfig.direction === "ascending" ? "↓" : "↑")}
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "alphabetical"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("alphabetical")}
            >
              {translations.ORDER_AL}{" "}
              {sortConfig.key === "alphabetical" &&
                (sortConfig.direction === "ascending" ? "↓" : "↑")}
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "rating"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("rating")}
            >
              {translations.ORDER_RA}{" "}
              {sortConfig.key === "rating" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col items-start bg-[#fff] dark:bg-[#31333e] overflow-y-auto">
          {sortedLis.length === 0 ? (
            <div className="flex flex-col justify-center items-center h-full w-full text-center p-4">
              <div className="text-gray-500 text-lg mb-2">
                {translations.list1}
              </div>
              <FaTimes className="text-gray-500 text-3xl" />
            </div>
          ) : (
            sortedLis.slice(0, visibleItems).map((item, index) => {
              if (item.psychologys && item.psychologys.length > 1) {
                return (
                  <div
                    key={index}
                    className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] border-b-0"
                    onClick={() => handleGoStudy(item)}
                  >
                    <div className="flex flex-col gap-[17px]">
                      <div className="flex flex-col gap-[12px]">
                        <div className="flex items-center">
                          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] dark:text-[#fff] whitespace-nowrap overflow-hidden text-ellipsis max-w-[80vw]">
                            {item.psychologys.find(
                              (customize) => customize.language === language
                            )?.title || "No title available"}
                          </div>
                        </div>
                        <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] whitespace-nowrap overflow-hidden text-ellipsis max-w-[80vw]">
                          {item.psychologys.find(
                            (customize) => customize.language === language2
                          )?.title || "No title available"}
                        </div>
                      </div>

                      <div className="flex items-center gap-[3px]">
                        {item.tag &&
                          item.tag.map((tag, tagIndex) => (
                            <div
                              key={tagIndex}
                              className="flex items-center gap-[3px]"
                            >
                              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                                {data
                                  ? findItemById(
                                      tag.id,
                                      data
                                    )?.translations.find(
                                      (t) => t.language === language
                                    )?.name
                                  : "Loading..."}
                              </div>
                              {tagIndex < item.tag.length - 1 && (
                                <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="flex items-center gap-[4px]">
                      <img
                        className={darkMode ? "filter invert" : ""}
                        width="15"
                        height="15"
                        src={`star.png`}
                        alt="rating star"
                      />
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] whitespace-nowrap">
                        {item.averageRating.toFixed(1)}
                      </div>
                    </div>
                  </div>
                );
              }
            })
          )}
          {visibleItems < sortedLis.length && (
            <div className="flex justify-center text-center py-[14px] bg-[#fff] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] w-[100%]">
              <button
                onClick={() =>
                  setVisibleItems((prevVisibleItems) => prevVisibleItems + 10)
                }
                className="px-4 py-2 bg-[#007bff] text-white rounded"
              >
                더보기
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PsychologyStudyList;
