import React, { useState, useEffect } from 'react';
import Navigation from '../../components/admin/Navigation';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/admin/icons/calendar.png';
import leftCursor from '../../assets/admin/icons/leftCursor.png';
import rightCursor from '../../assets/admin/icons/rightCursor.png';
import { getNoticesByPage, deleteNotice, getPinnedNotices } from '../../api/admin/notice';
import { ChevronDownIcon } from '@heroicons/react/solid';
import handleSortIcon from '../../assets/admin/icons/handleSortIcon.png';

const NoticeList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [isPinned, setIsPinned] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [isItemLimitDropdownOpen, setIsItemLimitDropdownOpen] = useState(false);
  const [isPinnedDropdownOpen, setIsPinnedDropdownOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  useEffect(() => {
    fetchNoticeData();
  }, [currentPage, itemsPerPage, isPinned, search, startDate, endDate, sortConfig]);

  const fetchNoticeData = async () => {
    try {
      const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        const tzOffset = d.getTimezoneOffset() * 60000;
        const localDate = new Date(d.getTime() - tzOffset);
        return localDate.toISOString().split('T')[0];
      };

      // endDate를 다음날로 설정
      const adjustedEndDate = endDate ? new Date(endDate) : null;
      if (adjustedEndDate) {
        adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
      }

      let response;
      let currentPageData;

      if (isPinned) {
        response = await getPinnedNotices();
        currentPageData = [...response];
        setTotalItems(response.length);
      } else {
        response = await getNoticesByPage(
          currentPage + 1,
          itemsPerPage,
          search,
          formatDate(startDate),
          formatDate(adjustedEndDate)
        );
        currentPageData = [...response.notices];
        setTotalItems(response.total);
      }

      // 현재 페이지의 데이터만 정렬
      if (sortConfig.key) {
        currentPageData = currentPageData.sort((a, b) => {
          const aValue = sortConfig.key.includes('.') 
            ? sortConfig.key.split('.').reduce((obj, key) => obj?.[key], a)
            : a[sortConfig.key];
          const bValue = sortConfig.key.includes('.')
            ? sortConfig.key.split('.').reduce((obj, key) => obj?.[key], b)
            : b[sortConfig.key];

          if (sortConfig.direction === 'ascending') {
            return aValue > bValue ? 1 : -1;
          } else {
            return aValue < bValue ? 1 : -1;
          }
        });
      }

      setData(currentPageData);
    } catch (error) {
      console.error('Error fetching notice data:', error);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(0);
  };

  const handlePinnedChange = (value) => {
    setIsPinned(value);
    setCurrentPage(0);
    setIsPinnedDropdownOpen(false);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setCurrentPage(0);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      alert('삭제할 항목을 선택해주세요.');
      return;
    }

    if (window.confirm('선택한 항목을 삭제하시겠습니까?')) {
      try {
        for (const id of selectedRows) {
          await deleteNotice(id);
        }
        setSelectedRows([]);
        fetchNoticeData();
      } catch (error) {
        console.error('삭제 실패:', error);
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]); // 모든 선택 해제
    } else {
      setSelectedRows(data.map(item => item.id)); // 모든 행 선택
    }
  };

  const handleCreateScript = async () => {
    try {
      navigate(`/notice-editor`);
    } catch (error) {
      console.error('Failed to create manager:', error);
    }
  };

  const handleItemLimitChange = (limit) => {
    setItemsPerPage(limit);
    setCurrentPage(0);
  };

  // 드롭다운 스타일
  const selectedDropdownStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 12px',
    borderRadius: '12px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E2E8F0',
    cursor: 'pointer',
  };

  // 정렬 핸들러 (정렬 기준 및 방향 설정)
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    
    // 현재 페이지의 데이터만 정렬
    const currentPageData = [...data].sort((a, b) => {
      const aValue = key.includes('.') 
        ? key.split('.').reduce((obj, k) => obj?.[k], a)
        : a[key];
      const bValue = key.includes('.')
        ? key.split('.').reduce((obj, k) => obj?.[k], b)
        : b[key];

      if (direction === 'ascending') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
    
    setData(currentPageData);
  };

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div style={{ padding: '26px 30px' }}>
        <h1 className="font-bold uppercase" style={{ width: '140px', height: '28px', fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700, textAlign: 'left' }}>
          공지사항
        </h1>

        {/* 검색 및 필터 영역 */}
        <div className="" style={{ margin: '26px 0px 20px 0' }}>
          <div className="flex items-center space-x-3">
            {/* 고정된 공지사항 필터 드롭다운 */}
            <div className="relative">
              <div
                className="flex items-center justify-between w-[200px] h-[36px] px-3 bg-white rounded-[12px] cursor-pointer"
                onClick={() => setIsPinnedDropdownOpen(!isPinnedDropdownOpen)}
              >
                <span style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#0E111F' }}>
                  {isPinned ? '고정된 공지사항만 보기' : '전체 공지사항 보기'}
                </span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>
              {isPinnedDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                  <div
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handlePinnedChange(false)}
                  >
                    전체 공지사항 보기
                  </div>
                  <div
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handlePinnedChange(true)}
                  >
                    고정된 공지사항만 보기
                  </div>
                </div>
              )}
            </div>

            {/* 검색어 입력 */}
            <div className="flex">
              <input
                type="text"
                placeholder="검색어를 입력하세요"
                value={search}
                onChange={handleSearch}
                className="h-[36px] w-[250px]"
                style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '10px 14px', borderRadius: '12px', color: '#A9B6CA' }}
              />
            </div>

            {/* 기간 필터 */}
            <div 
              className="relative flex items-center h-[36px] w-[250px] bg-white cursor-pointer" 
              style={{ borderRadius: '12px', padding: '0px 8px' }}
              onClick={() => {
                const datePickerInput = document.querySelector('.react-datepicker__input-container input');
                if (datePickerInput) {
                  datePickerInput.click();
                }
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  if (start) {
                    const startOfDay = new Date(start);
                    startOfDay.setHours(0, 0, 0, 0);
                    setStartDate(startOfDay);
                  } else {
                    setStartDate(null);
                  }
                  
                  if (end) {
                    const endOfDay = new Date(end);
                    endOfDay.setHours(23, 59, 59, 999);
                    setEndDate(endOfDay);
                  } else {
                    setEndDate(null);
                  }
                  setCurrentPage(0);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                isClearable={true}
                dateFormat="yyyy-MM-dd"
                placeholderText="기간 입력"
                className="w-full h-full"
                style={{ 
                  fontFamily: 'Pretendard', 
                  fontSize: '14px', 
                  fontWeight: 500,
                  border: 'none',
                  color: '#A9B6CA',
                  cursor: 'pointer',
                  pointerEvents: 'none'
                }}
              />
              <img 
                src={calendarIcon} 
                alt="Calendar Icon" 
                className="absolute right-2 w-7 h-6"
                style={{ pointerEvents: 'none' }}
              />
            </div>
          </div>
        </div>

        {/* 목록 테이블 섹션 */}
        <div className="bg-white rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <div className="text-lg mt-5 mb-7 ml-5">
              <label className="text-xl font-bold text-[#0E111F] mr-5" style={{ fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700 }}>
                목록
              </label>
              <label 
                className="text-sm font-medium text-[#0E111F]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                검색결과 {totalItems}개 | 선택 {selectedRows.length}개 | 현재 페이지 {currentPage + 1 }
              </label>
            </div>
            {/* 액션 버튼들 */}
            <div className="flex space-x-2 mr-5">

              {/* 항목 수 선택 */}
              <div className="relative mr-4">
                <div
                  style={selectedDropdownStyle}
                  onClick={() => setIsItemLimitDropdownOpen(!isItemLimitDropdownOpen)}
                >
                  <span>{`${itemsPerPage}개씩 보기`}</span>
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                </div>
                {isItemLimitDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                    {[5, 10, 20].map((limit) => (
                      <div
                        key={limit}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          handleItemLimitChange(limit);
                          setIsItemLimitDropdownOpen(false);
                        }}
                      >
                        {`${limit}개씩 보기`}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* 선택 삭제 버튼 */}
              <button 
                onClick={handleDelete} 
                className="p-2 rounded-md text-[#383C4B]"
                style={{
                  width: '100px',
                  height: '36px',
                  borderRadius: '12px',
                  background: '#C5D0DD',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                선택 삭제
              </button>
              <button 
                onClick={handleCreateScript} 
                className="p-2 rounded-md text-[#3677F6]"
                style={{
                  width: '100px',
                  height: '36px',
                  borderRadius: '12px',
                  background: '#E3EDFF',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                공지사항 등록
              </button>
            </div>
          </div>

          <table className="min-w-full divide-y divide-gray-200 shadow-md">
            <thead className="bg-[#E2E8EF]" style={{ height: '40px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#666B81' }}>
              <tr>
                <th className='text-center w-16'>
                  {/* 모든 항목 선택/해제 체크박스 */}
                  <input 
                    type="checkbox" 
                    onChange={handleSelectAll} 
                    checked={selectedRows.length === data.length} // 모든 항목이 선택되었는지 확인
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-16"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  No.
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-22"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  작성자
                </th>
                <th 
                  className="text-left font-medium text-gray-500 tracking-wider cursor-pointer w-109"
                  onClick={() => handleSort('title')}
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  제목<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  조회수
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  출력 여부
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider w-30"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  상단 고정
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44"
                  onClick={() => handleSort('createdAt')}
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  최초 등록일<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
                <th 
                  className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44"
                  onClick={() => handleSort('updatedAt')}
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#666B81',
                  }}
                >
                  마지막 수정일<img 
                      src={handleSortIcon} 
                      alt="Sort Icon" 
                      className="inline-block ml-1"
                      style={{ width: '22px', height: '22px' }}
                     />
                </th>
              </tr>
            </thead>
            <tbody 
              className="bg-white divide-y divide-gray-200"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000',
              }}
            >
              {data.map((item, index) => (
                <tr 
                  key={item.id} 
                  className='hover:bg-gray-100 transition duration-150 ease-in-out h-[40px]'
                  onClick={() => navigate(`/notice-editor/${item.id}`)} // 클릭 시 edit 모드로 이동
                >
                  <td className="whitespace-no-wrap text-center w-16" onClick={(e) => e.stopPropagation()}>
                    {/* 각 항목 선택 체크박스 */}
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(item.id)} // 선택 여부에 따라 체크
                      onChange={(e) => {
                        e.stopPropagation(); // 클릭 이벤트 전파 중지
                        handleSelectRow(item.id); // 선택된 항목 업데이트
                      }}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                  </td>
                  <td className="whitespace-no-wrap text-center">
                    {index + 1}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {item.author.username}
                  </td>
                  <td className="whitespace-no-wrap cursor-pointer">
                    {item.title}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {item.views}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {item.output ? 'YES' : 'NO'}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {item.isPinned ? 'YES' : 'NO'}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {new Date(item.createdAt).toLocaleDateString()}
                  </td>
                  <td className="whitespace-no-wrap text-center cursor-pointer">
                    {new Date(item.updatedAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* 페이지네이션 컴포넌트 */}
        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel={
              <img src={leftCursor} alt="Left Cursor" className="w-4 h-4" />
            }
            nextLabel={
              <img src={rightCursor} alt="Right Cursor" className="w-4 h-4" />
            }
            breakLabel={'...'} // 페이지 구분자
            pageCount={Math.ceil(totalItems / itemsPerPage)} // 총 페이지 수 계산
            marginPagesDisplayed={2} // 양 끝에 보여줄 페이지 수
            pageRangeDisplayed={5} // 현재 페이지 근처에 보여줄 페이지 수
            onPageChange={handlePageClick} // 페이지 변경 시 호출할 핸들러
            containerClassName={'pagination flex space-x-2 items-center'} // 페이지네이션 컨테이너 스타일
            activeClassName={'text-[#3677F6] font-bold'} // 현재 선택된 페이지 스타일
            breakClassName={'text-[#A9B6CA]'} // 구분자 스타일
            disabledClassName={'text-[#C5D0DD] cursor-not-allowed'} // 비활성화 스타일
          />
        </div>
      </div>
    </div>
  );
};

export default NoticeList;