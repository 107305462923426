import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StudyQuitModal from "./StudyQuitModal";
import { ProgressBar } from "./ProgressBar";

const StarScore = () => {
  const location = useLocation();
  const { pgs } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);
  const [rating, setRating] = useState(5);
  const type = useSelector((state) => state.type);
  const userInfo = useSelector((state) => state.userInfo);
  const manageId = useSelector((state) => state.manageId);
  const navigate = useNavigate();
  const textRef = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const accessToken = sessionStorage.getItem("accessToken");

  const updateRating = async () => {
    const data = {
      rating: rating, // replace with actual phone number
    };
    let url;

    try {
      if (type === "mbti" || type === "persona") {
        url = `${apiUrl}/customize/manage/${manageId}/rate`;
      } else {
        url = `${apiUrl}/${type}/manage/${manageId}/rate`;
      }
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authentication tokens
        },
        body: JSON.stringify(data), // Convert the data to JSON
      });
      if (response.ok) {
      }
    } catch (error) {}
  };

  const handleNextClick = async () => {
    setLoading(true);
    if (sessionStorage.getItem("starscoreS") === "false") {
      sessionStorage.removeItem("starscoreB");
      navigate("/storyboard");
      return null;
    }
    if (sessionStorage.getItem("starscoreB") === "false") {
      sessionStorage.removeItem("starscoreB");
      navigate("/bookmark");
      return null;
    }
    setLoading(false);
    try {
      const response = await fetch(`${apiUrl}/auth/increase-script-count`, {
        method: "POST", // Use DELETE method
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
          "Content-Type": "application/json",
        },
        // You can include a body if required by the API, but often DELETE requests don't have a body
        // body: JSON.stringify({ someData: "value" }),
      });

      if (response.ok) {
        // navigate("/welcome");
        const result = await response.json();

        updateRating();
        //
        // Handle successful deletion, e.g., redirect or show a message
      } else {
        // Handle error response
      }
    } catch (error) {
      // Handle request error
    }
    try {
      const response = await fetch(`${apiUrl}/recent-learnings`, {
        method: "POST", // Using POST method for sending data
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify({
          itemId: parseInt(sessionStorage.getItem("itemId"), 10), // Sending the required data
          itemType: type === "mbti" || type === "persona" ? "customize" : type,
          language: userInfo.targetLanguage,
        }), // Include the body data
      });

      if (response.ok) {
        const result = await response.json();

        updateRating();
        // Handle successful response, e.g., navigate to another page or show a message
      } else {
        // Handle error response
      }
    } catch (error) {
      // Handle request error
    }
    navigate(`/${type}studylist`);
  };
  const handleGoAssociated = () => {
    sessionStorage.removeItem("starscoreB");
    updateRating();
    navigate(`/${type}studylist`);
  };

  // Handle star click event
  const handleClick = (index) => {
    setRating(index + 1); // Update rating to the clicked star's index + 1
  };

  useEffect(() => {}, [rating]);

  useEffect(() => {
    if (textRef.current) {
      const amazingWidth = textRef.current.offsetWidth;
      setInputWidth(amazingWidth * 1.3); // 150% of the word "amazing" width
    }
  }, []);

  // first overlay
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // second overlay
  const [isOverlayVisible2, setOverlayVisible2] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState("E"); // Default to 'E'
  const overlayRef2 = useRef(null); // Ref for the overlay

  const handleDifficultyChange = (difficulty) => {
    setSelectedDifficulty(difficulty);
    setOverlayVisible2(false); // Optionally close the overlay after selecting
  };

  const handleClickOutside2 = (event) => {
    if (overlayRef2.current && !overlayRef2.current.contains(event.target)) {
      setOverlayVisible2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const [isModalVisible2, setisModalVisible2] = useState(false);

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };

  useEffect(() => {
    if (isModalVisible2) {
      const timer = setTimeout(() => {
        setisModalVisible2(false);
      }, 3000); // Auto-close after 3 seconds

      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [isModalVisible2]);

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  const handleGoStudy = () => {
    navigate(`/${type}studylist`);
  };
  const [isAnimating, setIsAnimating] = useState(false);
  const [stage, setStage] = useState("initial"); // Tracks the stage: initial, growing, disappearing
  const [image, setImage] = useState("bookmark.png"); // Tracks the current image

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimating(true);
      setStage("growing");
    }, 300);

    return () => clearTimeout(timeout); // Cleanup timeout
  }, []);
  useEffect(() => {
    let timeout;

    if (isAnimating) {
      if (stage === "growing") {
        // Transition to disappearing after 1s
        timeout = setTimeout(() => setStage("disappearing"), 800);
      } else if (stage === "disappearing") {
        // Transition back to initial size and change the image after 1s
        timeout = setTimeout(() => {
          setStage("initial");
          setImage("bookmark_img.svg"); // Change the image at the end
          setIsAnimating(false);
        }, 1000);
      }
    }

    return () => clearTimeout(timeout); // Cleanup timeout
  }, [isAnimating, stage]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      {isOverlayVisible2 && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="relative w-[100%] bg-[#faf8f6]">
        <div className="absolute left-0 top-[58%] w-full flex flex-col items-center justify-center"></div>

        <div className="absolute -translate-x-1/2 left-1/2 top-[10px] w-full h-[46px] overflow-hidden">
          {/* Header */}
          <div className="absolute top-[6px] left-0 right-0 flex items-center justify-between px-[16px]">
            {/* Left: xbutton */}
            <div className="w-[36px] flex items-center">
              <img
                width="10"
                height="16"
                src="back.png"
                alt="Close Button"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            {/* Center: Quiz Text */}
            <div className="text-[16px] font-sans font-semibold text-[#000]">
              Star Score
            </div>
            {/* Right: ddd2 Image */}
            <div className="w-[28px] flex items-center justify-end"></div>
          </div>
          {/* Progress Bar */}
          <div className="absolute bottom-0 left-0 w-full">
            <ProgressBar totalSections={pgs} filledSections={pgs} />
          </div>
        </div>
        {!loading && sessionStorage.getItem("starscoreB") !== "false" ? (
          <div className="absolute -translate-x-1/2 left-1/2 top-[80px] w-[91%] h-[65%]">
            <div className="absolute left-0 top-0 w-[100%] h-[100%] bg-[#fff] flex items-center justify-center">
              <div className="w-[277px] flex flex-col items-center justify-center gap-[10px]">
                <div className="self-stretch flex flex-col items-center justify-center gap-[8px] -mt-[50px]">
                  <div className="text-[16px] leading-[100%] font-sans text-[#000] whitespace-nowrap text-center">
                    이 학습의 별점을 남기고
                  </div>
                  <div className="text-[16px] leading-[100%] font-sans text-[#000] whitespace-nowrap text-center">
                    나에게 더 잘맞는 학습을 추천받으세요!
                  </div>
                  <div className="flex justify-center items-center gap-[4px] mt-[20px]">
                    {Array.from({ length: 5 }, (_, index) => (
                      <img
                        key={index}
                        src={
                          index < rating ? "bluestar.png" : "emptybluestar.png"
                        }
                        alt={`Star ${index + 1}`}
                        width="50px"
                        height="50px"
                        onClick={() => handleClick(index)}
                        className="cursor-pointer" // Makes the stars clickable
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="absolute -translate-x-1/2 left-1/2 top-[80px] w-[91%] h-[65%]">
            <div className="absolute left-0 top-0 w-[100%] h-[100%] bg-[#fff] flex items-center justify-center">
              <div className="w-[277px] flex flex-col items-center justify-center gap-[10px]">
                <div className="self-stretch flex flex-col items-center justify-center gap-[8px] -mt-[50px]">
                  <div className="text-[18px] leading-[100%] font-sans text-[#000] whitespace-nowrap text-center">
                    북마크 학습 완료
                  </div>

                  <div className="flex justify-center items-center mt-[20px]">
                    <img
                      src={image}
                      alt="Bookmark"
                      className={`transition-transform duration-1000 ease-in-out ${
                        stage === "growing"
                          ? "scale-[2]" // Grows to 2x size
                          : stage === "disappearing"
                          ? "scale-[2] opacity-0" // Max size + fades out
                          : "scale-[1] opacity-100" // Initial size
                      }`}
                      onClick={handleClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Overlay */}
        <div
          ref={overlayRef}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "20%",
            zIndex: 100,
            borderTopLeftRadius: "30px", // Adjust the radius value as needed
            borderTopRightRadius: "30px", // Adjust the radius value as needed
          }}
        >
          {/* Content of the overlay */}
          <div className="flex flex-col h-full">
            {/* Text Container 1 */}
            <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
              설정
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            {/* Text Container 2 */}
            <div className="flex flex-wrap px-[10px] items-center flex-grow font-sans">
              <div className="font-bold">Time Check</div>
              <div className="flex flex-wrap items-center justify-end pr-[10px] flex-grow font-sans">
                OFF·ON
              </div>
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            {/* Text Container 3 */}
            <div className="flex flex-wrap px-[10px] justify-start items-center flex-grow font-sans">
              <div className="font-bold">Confirm Button</div>
              <div className="flex flex-wrap items-center justify-end pr-[10px] flex-grow font-sans">
                OFF·ON
              </div>
            </div>
          </div>
        </div>

        {/* Overlay2 */}
        <div
          ref={overlayRef2}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible2
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "20%",
            zIndex: 100,
            borderTopLeftRadius: "30px", // Adjust the radius value as needed
            borderTopRightRadius: "30px", // Adjust the radius value as needed
          }}
        >
          {/* Content of the overlay2 */}
          <div className="flex flex-col h-full">
            {/* Text Container 1 */}
            <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
              난이도
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("E")}
            >
              EASY
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("N")}
            >
              NORMAL
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("D")}
            >
              DIFFICULT
            </div>
          </div>
        </div>

        {/* Modal */}
        {isModalVisible2 && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
            <div className="w-[60%] h-[20%] flex flex-col items-center justify-center gap-[10px] py-[20px] px-[34px] bg-[#e3edff] rounded-[8px] overflow-hidden animate-slideIn">
              <div className="text-[26px] font-sans font-black text-[#3677f6] whitespace-nowrap">
                Correct!
              </div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                설명이 들어가는 자리입니다
              </div>
              <button
                className="mt-4 px-4 py-2 bg-[#3677f6] text-white rounded"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
        <StudyQuitModal
          isVisible={isModalVisible}
          onClose={() => setModalVisible(false)}
          onAction1={handleModalAction1}
          onAction2={handleGoStudy}
        />
        <footer className="fixed w-full bottom-3 py-4 px-4">
          <button
            className="w-full h-[54px] flex items-center justify-center bg-gradient-to-r from-[#61e3eb] to-[#3677f6] rounded-[12px] text-[#fff] text-[14px] font-sans font-bold mb-2"
            onClick={handleNextClick}
          >
            학습완료하기
          </button>
          {!loading &&
          sessionStorage.getItem("starscoreB") === "false" ? null : (
            <div
              className="w-[100%] h-[54px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] border-[1px] border-solid border-[#3677f6] rounded-[12px]"
              onClick={handleGoAssociated}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                연계학습하기
              </div>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

export default StarScore;
