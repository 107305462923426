import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import Navigation from '../../components/admin/Navigation';
import { saveFaq, getFaqById } from '../../api/admin/faq';

const FaqEditor = ({ mode = 'create' }) => {
  const navigate = useNavigate();
  const { faqId } = useParams();
  
  // 상태 관리
  const [output, setOutput] = useState(true);
  const [category, setCategory] = useState('일반');
  
  const quillRef = useRef(null);
  const [content, setContent] = useState('');
  const quillInstance = useRef(null);

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (!quillInstance.current) {
      quillInstance.current = new Quill(quillRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],  // 기본 텍스트 서식
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],  // 리스트
            ['link'],  // 링크만 허용
          ]
        }
      });

      quillInstance.current.on('text-change', () => {
        setContent(quillInstance.current.root.innerHTML);
      });
    }
  }, []);

  useEffect(() => {
    const loadFaqData = async () => {
      try {
        if (mode === 'edit' && faqId) {
          const faq = await getFaqById(faqId);
          setTitle(faq.title);
          setOutput(faq.output);
          setCategory(faq.category);
          setContent(faq.content);
          quillInstance.current.root.innerHTML = faq.content;
        }
      } catch (error) {
        console.error('FAQ 데이터 로딩 실패:', error);
        alert('FAQ 데이터를 불러오는 데 실패했습니다.');
      }
    };

    loadFaqData();
  }, [mode, faqId]);

  const handleSave = async () => {
    try {
      const faqData = {
        title,
        content,
        category,
        authorId: 1,
        output,
      };

      await saveFaq(faqData, mode, faqId);
      alert(`FAQ가 성공적으로 ${mode === 'create' ? '추가' : '수정'}되었습니다.`);
      navigate('/faq');
    } catch (error) {
      console.error(`FAQ ${mode === 'create' ? '추가' : '수정'} 중 오류 발생:`, error);
      alert(`FAQ ${mode === 'create' ? '추가' : '수정'} 중 오류가 발생했습니다.`);
    }
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold text-left">{mode === 'create' ? 'FAQ 추가' : 'FAQ 수정'}</h1>
        <div className="flex space-x-4">
          <button
            onClick={() => navigate('/faq')}
            className="w-[100px] h-[48px] bg-gray-400 text-white font-bold px-4 py-3 rounded-xl"
          >
            목록가기
          </button>
          <button 
            onClick={handleSave}
            className="w-[100px] h-[48px] bg-blue-500 text-white font-bold px-4 py-3 rounded-xl"
          >
            저장하기
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl font-bold mb-4">상태값</h2>
        <div className="grid grid-cols-2 gap-8">
          <div>
            <label className="block text-sm font-bold mb-2">출력여부</label>
            <select
              value={output ? '출력' : '미출력'}
              onChange={(e) => setOutput(e.target.value === '출력')}
              className="w-full h-10 border border-gray-300 rounded-lg px-3"
            >
              <option value="출력">출력</option>
              <option value="미출력">미출력</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-bold mb-2">카테고리</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full h-10 border border-gray-300 rounded-lg px-3"
            >
              <option value="일반">일반</option>
              <option value="학습">학습</option>
              <option value="기능">기능</option>
              <option value="결제">결제</option>
              <option value="환불">환불</option>
            </select>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl font-bold mb-4">내용</h2>

        <div className="mb-6">
          <label className="block text-sm font-bold mb-2">질문</label>
          <input
            type="text"
            placeholder="질문을 입력하세요"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full h-10 border border-gray-300 rounded-lg px-3"
          />
        </div>

        <div>
          <label className="block text-sm font-bold mb-2">답변</label>
          <div className="h-[400px] pb-12">
            <div ref={quillRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqEditor;