export const NoItemsModal = ({ isVisible, onClose, language }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-4 shadow-md relative flex items-center justify-center">
        {/* Modal Text */}
        <div className="text-center text-sm font-semibold text-gray-800">
          {language === "kor" ? "학습할 단어가 없습니다" : "No more Vocabs"}
        </div>
      </div>
    </div>
  );
};
