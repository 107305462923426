import { useState, useEffect, useContext, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StudyQuitModal from "./StudyQuitModal";
import { useDifficulty } from "./DifficultyContext";
import BookmarkModal from "./BookmarkModal";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary
import { ProgressBar } from "./ProgressBar";

const QuizA = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const { script, id, pgs } = location.state || {};

  const { translations, language } = useContext(LanguageContext);
  const type = useSelector((state) => state.type);
  const navigate = useNavigate();
  const { difficulty, setDifficulty } = useDifficulty();
  const [isFirst, setIsFirst] = useState(true);
  const accessToken = sessionStorage.getItem("accessToken");
  const manageId = useSelector((state) => state.manageId);
  const where = useSelector((state) => state.where);
  const [bookmarkId, setBookmarkId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const oppositeLanguage = language === "kor" ? "eng" : "kor";
  const handleSwipeLeft = () => {
    sessionStorage.setItem("elapsedTime", elapsedTime);
    handleGoNext();
  };
  sessionStorage.removeItem("aswh");
  const [isConfirmCheckOn, setIsConfirmCheckOn] = useState(true); // Track Time Check state

  const [isTimeCheckOn, setIsTimeCheckOn] = useState(true); // Track Time Check state
  const [elapsedTime, setElapsedTime] = useState(
    parseInt(sessionStorage.getItem("elapsedTime"), 10) || 0
  ); // Track time in seconds
  const startTimeRef = useRef(Date.now() - elapsedTime * 1000);
  useEffect(() => {
    if (sessionStorage.getItem("cc") === "true") {
      setIsConfirmCheckOn(true);
    } else {
      setIsConfirmCheckOn(false);
    }
  }, []);
  // Function to format time in "1m 2s" format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes > 0 ? `${minutes}m ` : ""}${remainingSeconds}s`;
  };

  // Toggle time check state and reset elapsed time if OFF
  const toggleTimeCheck = () => {
    setIsTimeCheckOn((prev) => !prev);
    if (isTimeCheckOn) {
      setElapsedTime(0); // Reset time when turned off
    }
  };
  // Toggle confirm check state and reset elapsed time if OFF
  const toggleConfirmCheck = () => {
    setIsConfirmCheckOn((prev) => !prev);
    if (isConfirmCheckOn) {
      sessionStorage.setItem("cc", "false");
    } else {
      sessionStorage.setItem("cc", "true");
    }
  };

  // Effect to start/stop the timer when Time Check is toggled
  useEffect(() => {
    let timer;

    if (isTimeCheckOn) {
      timer = setInterval(() => {
        const currentTime = Date.now();
        const elapsed = Math.floor((currentTime - startTimeRef.current) / 1000); // Calculate elapsed time in seconds
        setElapsedTime(elapsed);
      }, 1000); // Update every second
    } else if (!isTimeCheckOn && timer) {
      clearInterval(timer);
    }

    return () => clearInterval(timer); // Clean up the timer on unmount
  }, [isTimeCheckOn]);

  const handleSwipeRight = () => {
    sessionStorage.setItem("elapsedTime", elapsedTime);
    handleGoBack();
  };
  UseSwipe(handleSwipeLeft, handleSwipeRight);

  const updateQuiz = async (isCorrect) => {
    const data = {
      isCorrect: isCorrect,
    };

    try {
      const response = await fetch(`${apiUrl}/auth/update-quiz-result`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
      } else {
        alert("Failed to update profile.");
      }
    } catch (error) {
      alert("An error occurred while updating the profile.");
    }
  };
  // first overlay
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [answer, setAnswer] = useState("");
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // second overlay
  const [isOverlayVisible2, setOverlayVisible2] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState("E"); // Default to 'E'
  const overlayRef2 = useRef(null); // Ref for the overlay

  const toggleOverlay2 = () => {
    if (isOverlayVisible2 === false) {
      setOverlayVisible2(!isOverlayVisible2);
    } else {
      setOverlayVisible2(!isOverlayVisible2);
    }
  };

  const handleDifficultyChange = (difficulty) => {
    if (difficulty === "E") {
      setDifficulty("easyId");
    } else if (difficulty === "N") {
      setDifficulty("normalId");
    } else {
      setDifficulty("difficultId");
    }
    setSelectedDifficulty(difficulty);
    setOverlayVisible2(false); // Optionally close the overlay after selecting
  };

  const getImageSrc = () => {
    switch (difficulty) {
      case "easyId":
        return "E.svg";
      case "normalId":
        return "N.svg";
      case "difficultId":
        return "D.svg";
      default:
        return "e"; // Default image
    }
  };

  const handleClickOutside2 = (event) => {
    if (overlayRef2.current && !overlayRef2.current.contains(event.target)) {
      setOverlayVisible2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const [isModalVisible2, setisModalVisible2] = useState(false);
  const [isModalVisible3, setisModalVisible3] = useState(false);

  const postQuizData = async () => {
    // Fetch the titles based on language
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }
    let type3;
    if (type === "mbti" || type === "persona") {
      type3 = "customizes";
    } else if (type === "situation") {
      type3 = "situations";
    } else {
      type3 = "psychologies";
    }
    const fetchData = async () => {
      const url = `https://pastellapp.com/api/${type2}/manage/${manageId}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        // Find the situation that matches the given language
        const engTitle =
          data[type3].find((customize) => customize.language === "eng")
            ?.title || "No English Title"; // Provide a fallback in case title is not found

        const korTitle =
          data[type3].find((customize) => customize.language === "kor")
            ?.title || "No Korean Title"; // Provide a fallback in case title is not found

        // Return both titles
        return { engTitle, korTitle };
      } catch (error) {
        return { engTitle: "No English Title", korTitle: "No Korean Title" }; // Return fallback titles if there's an error
      }
    };

    // Wait for fetchData to get the titles
    const { engTitle, korTitle } = await fetchData();

    const fetchData2 = async () => {
      try {
        let url;
        if (type === "mbti" || type === "persona") {
          url = `${apiUrl}/customize_quiz?scriptId=${script[oppositeLanguage][difficulty].id}`;
        } else {
          url = `${apiUrl}/${type}_quiz?scriptId=${script[oppositeLanguage][difficulty].id}`;
        }
        const response = await fetch(url);
        const fetchedData = await response.json();

        // Find index of the "ox" type item
        const oxIndex = fetchedData.findIndex((item) => item.type === "ox");
        if (oxIndex !== -1) {
          return fetchedData[oxIndex].id; // Set the found item
        } else {
          return null;
        }
      } catch (error) {}
    };

    const partnerQuizId = await fetchData2();
    console.log("ㅠㅏ트너", partnerQuizId);

    const url = `${apiUrl}/wrong-answers/create`;
    const bodyData = {
      nativeTitle: engTitle,
      targetTitle: korTitle,
      // quizId: script[language][difficulty].id,
      quizId: data.id,
      partnerQuizId: partnerQuizId,
      quizType: difficulty.replace("Id", ""),
      manageType:
        type === "mbti" || type === "persona"
          ? "customize"
          : type === "situation"
          ? "situation"
          : "psychology",
      wrongAnswer: answer,
    };

    // Perform the POST request
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(bodyData),
      });
      if (response.ok) {
        const result = await response.json();
      } else {
      }
    } catch (error) {}
  };

  const handleGoClick = () => {
    if (answer === data.result) {
      updateQuiz(true);
      setisModalVisible2(true);
    } else {
      updateQuiz(false);
      postQuizData();
      setisModalVisible3(true);
    }
  };

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };
  const handleCloseModal3 = () => {
    setisModalVisible3(false);
  };

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  const handleGoStudy = () => {
    if (where === "study") {
      navigate(`/${type}studylist`);
    } else if (where === "home") {
      navigate("/home");
    } else if (where === "storyboard") {
      navigate("/storyboard");
    } else {
      navigate(`/bookmark`);
    }
  };
  // bookmark
  const [selectedMark, setSelectedMark] = useState(false); // Default to 'E'

  useEffect(() => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }
    const fetchData = async () => {
      const response = await fetch("https://pastellapp.com/api/bookmarks/", {
        method: "GET", // or 'POST' depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Include other headers if needed
        },
      });

      if (response.ok) {
        const data3 = await response.json();

        const itemFound = data3.results.some(
          (item) =>
            item.itemId === manageId &&
            item.itemType === type2 &&
            ((item.level === "easy" && difficulty === "easyId") ||
              (item.level === "normal" && difficulty === "normalId") ||
              (item.level === "difficult" && difficulty === "difficultId"))
        );
        // Update the selectedMark state based on whether the item is found
        setSelectedMark(itemFound);
      } else {
        // Handle the error as needed
      }
    };

    fetchData();
  }, [difficulty]); // Empty dependency array ensures this runs only once when the component mounts

  const updateBookmark = async () => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }
    const postData = {
      itemId: parseInt(manageId, 10),
      itemType: type2,
    };

    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
      body: JSON.stringify(postData), // Convert the data to JSON string
    });

    if (response.ok) {
      const data3 = await response.json();
    } else {
      // Handle the error as needed
    }
  };

  const findId = async () => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }

    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data3 = await response.json();

      const itemFound = data3.results.find(
        (item) => item.itemId === manageId && item.itemType === type2
      );

      if (itemFound) {
        return new Promise((resolve) => {
          setBookmarkId(itemFound.id);
          resolve(itemFound.id); // Resolve with the found id
        });
      } else {
        return Promise.reject("Item not found"); // Reject the promise if not found
      }
    } else {
      return Promise.reject("Failed to fetch data"); // Reject the promise if fetch fails
    }
  };

  const deleteBookmark = async (id) => {
    const response = await fetch(`https://pastellapp.com/api/bookmarks/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
    });

    if (response.ok) {
      const data3 = await response.json();
    } else {
      // Handle the error as needed
    }
  };

  const handleMarkAction = async () => {
    if (!selectedMark) {
      await updateBookmark();
      setSelectedMark(true);
      setShowModal(true);
    } else {
      try {
        const id = await findId(); // Wait for findId to resolve with the bookmarkId
        await deleteBookmark(id); // Pass the bookmarkId to deleteBookmark
        setSelectedMark(false);
      } catch (error) {}
    }
  };

  const getMarkImage = () => {
    switch (selectedMark) {
      case false:
        return "bookmark.png";
      case true:
        return "bookmark2.png";
      default:
        return "bookmark.png"; // Default image
    }
  };

  // State to track the current selected image ("x" or "o") or none
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (isConfirmCheckOn || !isFirst) return;

    // Call handleGoClick only after `answer` state is updated
    if (answer !== null) {
      handleGoClick();
      setIsFirst(false);
    }
  }, [answer]);
  // Handle clicking on an image
  const handleClick = (image) => {
    if (selected === image) {
      // If the same image is clicked again, toggle it back to "before" state
      setSelected(null);
    } else {
      // Otherwise, set the clicked image as the selected one
      setSelected(image);
      if (image === "x") {
        setAnswer("false");
      } else {
        setAnswer("true");
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
    sessionStorage.setItem("elapsedTime", elapsedTime);
  };
  const handleGoNext = () => {
    if (data2 === "x") {
      navigate("/starscore", { state: { pgs: pgs } });
      sessionStorage.setItem("elapsedTime", elapsedTime);
    } else {
      sessionStorage.setItem("elapsedTime", elapsedTime);
      navigate("/quizb", { state: { script: script, id: id, pgs: pgs } });
    }
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url;
        if (type === "mbti" || type === "persona") {
          url = `${apiUrl}/customize_quiz?scriptId=${script[language][difficulty].id}`;
        } else {
          url = `${apiUrl}/${type}_quiz?scriptId=${script[language][difficulty].id}`;
        }
        const response = await fetch(url);
        const fetchedData = await response.json();

        // Find index of the "ox" type item
        const oxIndex = fetchedData.findIndex((item) => item.type === "ox");
        const blankIndex = fetchedData.length === 2 ? "ok" : "x";

        // Check if the item exists
        if (oxIndex !== -1) {
          setData(fetchedData[oxIndex]); // Set the found item

          setData2(blankIndex); // Set the found item
        } else {
        }
      } catch (error) {}
    };

    fetchData();
  }, [apiUrl, language, difficulty]);
  return (
    <div
      id="swipe-container"
      className="flex min-h-screen justify-center w-full bg-[#faf8f6]"
    >
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      {isOverlayVisible2 && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="relative w-[100%] bg-[#faf8f6]">
        <div className="fixed bottom-5 w-full flex flex-col items-center justify-center">
          <div className="flex-1 flex flex-row items-start justify-center gap-[24px]">
            <div
              className="relative w-[30px] h-[30px] shrink-0"
              onClick={handleGoBack}
            >
              <img
                className="relative top-[50%]"
                width="18"
                height="18"
                src="fb.png"
              ></img>
            </div>
            <div
              className="relative w-[50px] h-[70px] shrink-0 flex"
              onClick={toggleOverlay2}
            >
              <div className="absolute left-0 top-0 flex flex-col items-center justify-start gap-[8px]">
                <div className="relative w-[50px] h-[50px] shrink-0">
                  <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
                  <img
                    className="absolute -translate-x-1/2 left-1/2 -translate-y-1/2 top-1/2"
                    width="24"
                    src={getImageSrc()}
                  ></img>
                </div>
                <div className="self-stretch text-[12px] leading-[100%] font-sans text-[#000] text-center">
                  {translations.DIFFICULTY}
                </div>
              </div>
            </div>
            {isConfirmCheckOn ? (
              <div
                className="relative w-[50px] h-[70px] shrink-0 flex"
                onClick={() => {
                  if (isFirst) {
                    handleGoClick();
                    setIsFirst(false);
                  }
                }}
              >
                <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-full"></div>
                <div className="absolute left-1/2 top-[57px] w-[52px] h-[12px] text-[12px] leading-[100%] font-sans text-[#000] text-center transform -translate-x-1/2">
                  {translations.GRADE}
                </div>
                <img
                  className="absolute left-[16px] top-[20px]"
                  width="18"
                  height="12"
                  src="Vector 19446_196.png"
                ></img>
              </div>
            ) : (
              <></>
            )}
            <div
              className="relative w-[50px] h-[70px] shrink-0 flex"
              onClick={handleMarkAction}
            >
              <div className="absolute left-0 top-0 w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
              <div className="absolute left-1/2 top-[57px] w-[52px] h-[12px] text-[12px] leading-[100%] font-sans text-[#000] text-center transform -translate-x-1/2">
                {translations.BOOKMARK}
              </div>
              <img
                className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-[calc(50%+-9px)]"
                width="13"
                height="24"
                src={getMarkImage()}
              ></img>
            </div>
            <div
              className="relative w-[30px] h-[30px] shrink-0"
              onClick={handleGoNext}
            >
              <img
                className="relative top-[50%]"
                width="18"
                height="18"
                src="ff.png"
              ></img>
            </div>
          </div>
        </div>

        <div className="absolute -translate-x-1/2 left-1/2 top-[10px] w-full h-[46px] overflow-hidden">
          {/* Header */}
          <div className="absolute top-0 left-0 right-0 flex items-center justify-between px-[16px]">
            {/* Left: xbutton */}
            <div className="w-[36px] flex items-center">
              <div className="relative w-[36px] h-[36px]">
                <img
                  className="w-full h-full"
                  src="xbutton.png"
                  alt="Close Button"
                  onClick={toggleModal}
                />
              </div>
            </div>
            {/* Center: Quiz Text */}
            <div className="text-[16px] font-sans font-semibold text-[#000]">
              Quiz
            </div>
            {/* Right: ddd2 Image */}
            <div className="w-[28px] flex items-center justify-end">
              <img
                width="28"
                height="28"
                src="ddd2.png"
                alt="Overlay Toggle"
                onClick={toggleOverlay}
              />
            </div>
          </div>
          {/* Progress Bar */}
          <div className="absolute bottom-0 left-0 w-full">
            <ProgressBar totalSections={pgs} filledSections={3} />
          </div>
        </div>

        <div className="absolute -translate-x-1/2 left-1/2 top-[80px] w-[91%] h-[60%]">
          {/* Elapsed time display */}
          {isTimeCheckOn && (
            <div className="absolute top-[2%] left-[2%] text-[14px] font-sans font-semibold text-[#000] z-10">
              {formatTime(elapsedTime)}
            </div>
          )}
          <div className="absolute left-[87%] top-[2%] flex flex-row items-center justify-start z-10">
            <LanguageSwitcher></LanguageSwitcher>
          </div>
          <div className="absolute left-0 top-0 w-[100%] h-[100%] bg-[#fff]">
            <div className="absolute -translate-x-1/2 left-1/2 top-[30%] w-[277px] flex flex-col items-start justify-start gap-[10px]">
              <div className="self-stretch text-[18px] leading-[28px] font-sans font-bold text-[#000]">
                Quiz 1.
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch flex flex-row items-center justify-start">
                  <div className="text-[16px] leading-[100%] font-sans text-[#000] break-word">
                    {data.question}
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute left-0 right-0 bottom-[60px] flex justify-center gap-[20px]">
              <img
                width="100"
                height="100"
                src={
                  selected === "x" && isFirst ? "x_after.png" : "x_before.png"
                }
                alt="Image 1"
                onClick={() => handleClick("x")}
                className="cursor-pointer" // Make the image look clickable
              />
              <img
                width="100"
                height="100"
                src={
                  selected === "o" && isFirst ? "o_after.png" : "o_before.png"
                }
                alt="Image 2"
                onClick={() => handleClick("o")}
                className="cursor-pointer" // Make the image look clickable
              />
            </div>
          </div>
        </div>
        {/* Overlay */}
        <div
          ref={overlayRef}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "20%",
            zIndex: 100,
            borderTopLeftRadius: "30px", // Adjust the radius value as needed
            borderTopRightRadius: "30px", // Adjust the radius value as needed
          }}
        >
          {/* Content of the overlay */}
          <div className="flex flex-col h-full">
            {/* Text Container 1 */}
            <div className="flex items-center justify-between px-[20px] flex-grow font-sans">
              <div className="font-bold">Time Check</div>
              <div
                className="flex items-center gap-1 font-sans cursor-pointer"
                onClick={toggleTimeCheck}
              >
                <span
                  className={`${
                    !isTimeCheckOn ? "text-blue-500" : "text-black"
                  }`}
                >
                  OFF
                </span>
                <span>·</span>
                <span
                  className={`${
                    isTimeCheckOn ? "text-blue-500" : "text-black"
                  }`}
                >
                  ON
                </span>
              </div>
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>
            {/* Text Container 1 */}
            <div className="flex items-center justify-between px-[20px] flex-grow font-sans">
              <div className="font-bold">Confirm Button</div>
              <div
                className="flex items-center gap-1 font-sans cursor-pointer"
                onClick={toggleConfirmCheck}
              >
                <span
                  className={`${
                    !isConfirmCheckOn ? "text-blue-500" : "text-black"
                  }`}
                >
                  OFF
                </span>
                <span>·</span>
                <span
                  className={`${
                    isConfirmCheckOn ? "text-blue-500" : "text-black"
                  }`}
                >
                  ON
                </span>
              </div>
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            {/* Text Container 2 */}
            <div
              className="flex items-center justify-center px-[10px] flex-grow font-sans cursor-pointer"
              onClick={() => {
                sessionStorage.setItem("elapsedTime", elapsedTime);
                navigate("/error-action");
              }}
            >
              <div className="font-bold">{translations.REPORT_ERROR}</div>
            </div>
          </div>
        </div>

        {/* Overlay2 */}
        <div
          ref={overlayRef2}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible2
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "20%",
            zIndex: 100,
            borderTopLeftRadius: "30px", // Adjust the radius value as needed
            borderTopRightRadius: "30px", // Adjust the radius value as needed
          }}
        >
          {/* Content of the overlay2 */}
          <div className="flex flex-col h-full">
            {/* Text Container 1 */}
            <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
              난이도
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("E")}
            >
              EASY
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("N")}
            >
              NORMAL
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
              onClick={() => handleDifficultyChange("D")}
            >
              DIFFICULT
            </div>
          </div>
        </div>

        {/* Modal */}
        {isModalVisible2 && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
            <div className="w-[60%] max-h-[80%] flex flex-col items-center justify-center gap-[10px] py-[20px] px-[34px] bg-[#e3edff] rounded-[8px] overflow-y-auto animate-slideIn">
              <div className="text-[26px] font-sans font-black text-[#3677f6] whitespace-nowrap">
                Correct!
              </div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                정답 : {data.result === "true" ? "O" : "X"}
              </div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] break-word">
                해설 : {data.explanation}
              </div>
              <button
                className="mt-4 px-4 py-2 bg-[#3677f6] text-white rounded"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {/* Modal */}
        {isModalVisible3 && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
            <div className="w-[60%] max-h-[80%] flex flex-col items-center justify-center gap-[10px] py-[20px] px-[34px] bg-[#e5592d] rounded-[8px] overflow-y-auto animate-slideIn">
              <div className="text-[26px] font-sans font-black text-[#fff] whitespace-nowrap">
                Wrong
              </div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
                정답 : {data.result === "true" ? "O" : "X"}
              </div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#fff] break-word">
                해설 : {data.explanation}
              </div>
              <button
                className="mt-4 px-4 py-2 bg-[#ea6e46] text-white rounded"
                onClick={handleCloseModal3}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <StudyQuitModal
          isVisible={isModalVisible}
          onClose={() => setModalVisible(false)}
          onAction1={handleModalAction1}
          onAction2={handleGoStudy}
        />
        <BookmarkModal show={showModal} onClose={() => setShowModal(false)} />
      </div>
    </div>
  );
};

export default QuizA;
